import { ChainId, Token, WETH } from '@pancakeswap/sdk'
import { LFG_ARB, LFG_CORE, LFG_TEST, USDC, USDT } from './common'

export const arbTokens = {
  weth: WETH[ChainId.ARBITRUM],
  // bnb here points to the wbnb contract. Wherever the currency WETH is required, conditional checks for the symbol 'WETH' can be used
  lfg: LFG_ARB,
  usdc: USDC[ChainId.ARBITRUM],
  usdt: USDT[ChainId.ARBITRUM],
  usdt_test: new Token(
    ChainId.ARBITRUM,
    '0xE4e55fA05A24Bb77B6039F4352AB4b8535bb8a24',
    6,
    'USDT',
    'USDT',
    'https://www.binance.com/',
  ),
  arb: new Token(
    ChainId.ARBITRUM,
    '0x912CE59144191C1204E64559FE8253a0e49E6548',
    18,
    'ARB',
    'Arbitrum',
    'https://arbitrum.io/',
  ),
  dai: new Token(
    ChainId.ARBITRUM,
    '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
    18,
    'DAI',
    'Dai Stablecoin',
    'https://makerdao.com/en/',
  ),
  gmx: new Token(ChainId.ARBITRUM, '0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a', 18, 'GMX', 'GMX', 'https://gmx.io/'),
  wow: new Token(
    ChainId.ARBITRUM,
    '0xFd01Cdb7F60Da7BD1A7645e4BCE53a3a3730A3Fe',
    18,
    'WOW',
    'WOW',
    'https://www.lfgswap.finance/',
  ),
  mts: new Token(
    ChainId.ARBITRUM,
    '0xAf1B86E67E554CF36E3e8F080cCCA2d995f6919D',
    18,
    'MTS',
    'MTS',
    'https://moontouch.io/',
  ),
  home: new Token(
    ChainId.ARBITRUM,
    '0x1bD013bD089C2B6b2D30a0e0B545810a5844E761',
    18,
    'HOME',
    'Home',
    'https://otterhome.finance/',
  ),

  lfg_receit: new Token(
    ChainId.ARBITRUM,
    '0xa5513dA4039999d36c918Df641d6328Afc48F9f8',
    18,
    'LFG(BASE)',
    'LFG(BASE)',
    '',
  ),
}
