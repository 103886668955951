import { ChainId } from '@pancakeswap/sdk'
import { BIG_TEN } from 'utils/bigNumber'
import { zks } from '../../packages/wagmi/chains/chains'

export const BSC_BLOCK_TIME = 12
export const CORE_BLOCK_TIME = 3

export const BASE_BSC_SCAN_URLS = {
  [ChainId.ARBITRUM]: 'https://arbiscan.io/',
  [ChainId.CORE]: 'https://scan.coredao.org',
  [ChainId.BSC]: 'https://mainnet.ethwscan.com',
  [ChainId.BSC_TESTNET]: 'https://testnet.ethwscan.com',
  [ChainId.ZKS]: zks.blockExplorers.default,
}

export const BLOCKS_PER_YEAR = {
  [ChainId.ARBITRUM]: (60 / CORE_BLOCK_TIME) * 60 * 24 * 365,
  [ChainId.CORE]: (60 / CORE_BLOCK_TIME) * 60 * 24 * 365,
  [ChainId.BSC]: (60 / BSC_BLOCK_TIME) * 60 * 24 * 365,
  [ChainId.BSC_TESTNET]: 'https://testnet.ethwscan.com',
  [ChainId.ZKS]:  (60 / CORE_BLOCK_TIME) * 60 * 24 * 365,
}

// CAKE_PER_BLOCK details
// 40 LFG is minted per block
// 20 LFG per block is sent to Burn pool (A farm just for burning cake)
// 10 LFG per block goes to LFG syrup pool
// 9 LFG per block goes to Yield farms and lottery
// CAKE_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// LFG/Block in src/views/Home/components/CakeDataRow.tsx = 15 (40 - Amount sent to burn pool)
export const CAKE_PER_BLOCK = 40
export const CORE_BLOCKS_PER_YEAR = (60 / CORE_BLOCK_TIME) * 60 * 24 * 365 // 10512000
export const CAKE_PER_YEAR = CAKE_PER_BLOCK * CORE_BLOCKS_PER_YEAR
export const BASE_URL = 'https://lfgswap.finance'
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/add`
export const BASE_BSC_SCAN_URL = (chainId) => BASE_BSC_SCAN_URLS[chainId]
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)
export const DEFAULT_GAS_LIMIT = 250000
export const BOOSTED_FARM_GAS_LIMIT = 1500000
export const AUCTION_BIDDERS_TO_FETCH = 500
export const RECLAIM_AUCTIONS_TO_FETCH = 500
export const AUCTION_WHITELISTED_BIDDERS_TO_FETCH = 500
export const IPFS_GATEWAY = 'https://ipfs.io/ipfs'
