import { rinkeby as defaultRinkeby, mainnet as defaultMainnet, goerli as defaultGoerli } from 'wagmi/chains'
import { Chain } from 'wagmi'

export const avalandche: Chain = {
  id: 43114,
  name: 'Avalanche C-Chain',
  network: 'avalanche',
  rpcUrls: {
    default: 'https://rpc.ankr.com/avalanche',
  },
  nativeCurrency: { name: 'Avalanche', symbol: 'AVAX', decimals: 18 },
  blockExplorers: {
    default: {
      name: 'snowtrace',
      url: 'https://snowtrace.io/',
    },
  },
}

export const avalandcheFuji: Chain = {
  id: 43113,
  name: 'Avalanche Fuji',
  network: 'avalanche-fuji',
  rpcUrls: {
    default: 'https://rpc.ankr.com/avalanche_fuji',
  },
  nativeCurrency: { name: 'Avalanche', symbol: 'AVAX', decimals: 18 },
  blockExplorers: {
    default: {
      name: 'snowtrace',
      url: 'https://testnet.snowtrace.io/',
    },
  },
  testnet: true,
}

export const fantomOpera: Chain = {
  id: 250,
  name: 'Fantom Opera',
  network: 'fantom',
  nativeCurrency: { name: 'Fantom', symbol: 'FTM', decimals: 18 },
  rpcUrls: {
    default: 'https://rpc.ftm.tools',
  },
  blockExplorers: {
    default: {
      name: 'FTMScan',
      url: 'https://ftmscan.com',
    },
  },
}

export const fantomTestnet: Chain = {
  id: 4002,
  name: 'Fantom Testnet',
  network: 'fantom-testnet',
  nativeCurrency: { name: 'Fantom', symbol: 'FTM', decimals: 18 },
  rpcUrls: {
    default: 'https://rpc.testnet.fantom.network',
  },
  blockExplorers: {
    default: {
      name: 'FTMScan',
      url: 'https://testnet.ftmscan.com',
    },
  },
  testnet: true,
}

const bscExplorer = { name: 'ethwscan', url: 'https://mainnet.ethwscan.com' }
const coreExplorer = { name: 'corescan', url: 'https://scan.coredao.org' }
const zksExplorer = { name: 'zksscan', url: 'https://explorer.zksync.io' }

export const bsc: Chain = {
  id: 10001,
  name: 'EthereumPoW',
  network: 'ETHW',
  rpcUrls: {
    default: 'https://mainnet.ethereumpow.org',
  },
  blockExplorers: {
    default: bscExplorer,
    ethwscan: bscExplorer,
  },
  nativeCurrency: {
    name: 'EthereumPow',
    symbol: 'ETHW',
    decimals: 18,
  },
  multicall: {
    address: '0x49f57e55E705082efdAb6702379679F50a37Ee02',
    blockCreated: 27283519,
  },
  ens: {
    address: '0x00000000003dB7663112d1e121c6D2bb8220F671',
  },
}

export const core: Chain = {
  id: 1116,
  name: 'Core Blockchain',
  network: 'Core',
  rpcUrls: {
    public: 'https://core.nodeproviders.com',
    default: 'https://rpc.coredao.org',
  },
  blockExplorers: {
    default: coreExplorer,
    corescan: coreExplorer,
  },
  nativeCurrency: {
    name: 'Core',
    symbol: 'CORE',
    decimals: 18,
  },
  multicall: {
    address: '0xE5552e0318531F9Ec585c83bDc8956C08Bf74b71',
    blockCreated: 27283519,
  },
  ens: {
    address: '0x00000000003dB7663112d1e121c6D2bb8220F671',
  },
}

export const arb: Chain = {
  id: 42161,
  name: 'Arbitrum One',
  network: 'Arbitrum',
  rpcUrls: {
    default: 'https://arb1.arbitrum.io/rpc',
  },
  blockExplorers: {
    default: { name: 'arbiscan', url: 'https://arbiscan.io/' },
    corescan: { name: 'arbiscan', url: 'https://arbiscan.io/' },
  },
  nativeCurrency: {
    name: 'Ethereum',
    symbol: 'ETH',
    decimals: 18,
  },
  multicall: {
    address: '0x13cfd7cAb59782A2e6C2d46E4F769F42c225c877',
    blockCreated: 52320,
  },
}
export const zks: Chain = {
  id: 324,
  name: 'zkSync',
  network: 'zks',
  rpcUrls: {
    default: 'https://mainnet.era.zksync.io',
  },
  blockExplorers: {
    default: zksExplorer,
    corescan: zksExplorer,
  },
  nativeCurrency: {
    name: 'Ethereum',
    symbol: 'ETH',
    decimals: 18,
  },
  multicall: {
    address: '0xe85764f997ff20c3538F57fAc9bffcF6aC050537',
    blockCreated: 2893921,
  },
}
const baseExplorer = { name: 'basescan', url: 'https://basescan.org' }
export const base: Chain = {
  id: 8453,
  name: 'BaseChain',
  network: 'base',
  rpcUrls: {
    default: 'https://developer-access-mainnet.base.org',
  },
  blockExplorers: {
    default: baseExplorer,
    corescan: baseExplorer,
  },
  nativeCurrency: {
    name: 'Ethereum',
    symbol: 'ETH',
    decimals: 18,
  },
  multicall: {
    address: '0x39bbbe0A685efb06070352d0EEe3A1f05BF5d07e',
    blockCreated: 2893921,
  },
}
export const bscTest: Chain = {
  id: 97,
  name: 'EthereumPoW Testnet',
  network: 'bsc-testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Binance Chain Native Token',
    symbol: 'tBNB',
  },
  rpcUrls: {
    default: 'https://data-seed-prebsc-1-s2.binance.org:8545/',
    // https://docs.nodereal.io/nodereal/meganode/meganode-api-overview/public-api-key
    nodeReal: 'https://bsc-testnet.nodereal.io/v1/e9a36765eb8a40b9bd12e680a1fd2bc5',
  },
  blockExplorers: {
    default: { name: 'ethwscan', url: 'https://testnet.ethwscan.com' },
  },
  multicall: {
    address: '0xb66a4fE12138C4391A98F29E34EFE4Cc7A445AE5',
    blockCreated: 21965366,
  },
  testnet: true,
}
const opBNBExploer = { name: 'opbnbscan', url: 'https://mainnet.opbnbscan.com' }
export const opBNB: Chain = {
  id: 204,
  name: 'opBNB Mainnet',
  network: 'opBNB Mainnet',
  rpcUrls: {
    default: 'https://opbnb-mainnet-rpc.bnbchain.org',
  },
  blockExplorers: {
    default: opBNBExploer,
    ethwscan: opBNBExploer,
  },
  nativeCurrency: {
    name: 'BNB',
    symbol: 'BNB',
    decimals: 18,
  },
  multicall: {
    address: '0xf66cef53c518659bFA0A9a4Aa07445AF08bf9B3a',
    blockCreated: 1694837317979,
  },
  ens: {
    address: '0x00000000003dB7663112d1e121c6D2bb8220F671',
  },
}

export const rinkeby: Chain = {
  ...defaultRinkeby,
  rpcUrls: {
    ...defaultRinkeby.rpcUrls,
    // https://docs.nodereal.io/nodereal/meganode/meganode-api-overview/public-api-key
    nodeReal: 'https://eth-rinkeby.nodereal.io/v1/a4da384bf3334c5ea992eb0bf44135e0',
  },
}

export const mainnet: Chain = {
  ...defaultMainnet,
  rpcUrls: {
    ...defaultMainnet.rpcUrls,
    // https://docs.nodereal.io/nodereal/meganode/meganode-api-overview/public-api-key
    nodeReal: 'https://eth-mainnet.nodereal.io/v1/1659dfb40aa24bbb8153a677b98064d7',
  },
}

export const goerli: Chain = {
  ...defaultGoerli,
  rpcUrls: {
    ...defaultGoerli.rpcUrls,
    // https://docs.nodereal.io/nodereal/meganode/meganode-api-overview/public-api-key
    nodeReal: 'https://eth-goerli.nodereal.io/v1/8a4432e42df94dcca2814fde8aea2a2e',
  },
}
