import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  TrophyIcon,
  TrophyFillIcon,
  NftIcon,
  NftFillIcon,
  MoreIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
// import { nftsBaseUrl } from 'views/Nft/market/constants'
import { perpLangMap } from 'utils/getPerpetualLanguageCode'
import { perpTheme } from 'utils/getPerpetualTheme'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
import { SUPPORT_ONLY_BSC } from 'config/constants/supportChains'
import { nftsBaseUrl } from 'views/Nft/market/constants'
import { ChainId } from '../../../../packages/swap-sdk/src/constants'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  account: string,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, account, languageCode, chainId) =>
  [
    {
      label: t('Trade'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: '/swap',
      showItemsOnMobile: false,
      items: [
        {
          label: t('Swap'),
          href: '/swap',
        },
        // {
        //   label: t('Limit'),
        //   href: '/limit-orders',
        //   supportChainIds: SUPPORT_ONLY_BSC,
        //   image: '/images/decorations/3d-coin.png',
        // },
        {
          label: t('Liquidity'),
          href: '/liquidity',
        },
        // {
        //   label: t('Perpetual'),
        //   href: `https://perp.lfgswap.finance/${perpLangMap(languageCode)}/futures/BTCUSDT?theme=${perpTheme(
        //     isDark,
        //   )}`,
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // },
        // {
        //   label: t('Bridge'),
        //   href: 'https://openapi.chainge.finance/app?fromChain=ETH&toChain=CORE&fromToken=USDT&toToken=USDT',
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t('Bridge'),
      // href: 'https://bridge.arbitrum.io/?l2ChainId=42161',
      icon: TrophyIcon,
      fillIcon: TrophyFillIcon,
      showItemsOnMobile: true,
      items:
        chainId === 8453
          ? [
              {
                label: t('Chainge'),
                href: `https://dapp.chainge.finance/?fromChain=ETH&toChain=BASE&fromToken=ETH&toToken=ETH`,
                type: DropdownMenuItemType.EXTERNAL_LINK,
              },
            ]
          : chainId === 1116
          ? [
              {
                label: t('Chainge'),
                href: `https://dapp.chainge.finance?fromChain=ETH&toChain=CORE&fromToken=USDT&toToken=USDT`,
                type: DropdownMenuItemType.EXTERNAL_LINK,
              },
              {
                label: t('CoreDAO'),
                href: `https://bridge.coredao.org/bridge`,
                type: DropdownMenuItemType.EXTERNAL_LINK,
              },
            ].map((item) => addMenuItemSupported(item, chainId))
          : [
              {
                label: t('Chainge'),
                href: `https://dapp.chainge.finance/?fromChain=BSC&toChain=OPBNB&fromToken=BNB&toToken=BNB`,
                type: DropdownMenuItemType.EXTERNAL_LINK,
              },
              // {
              //   label: t('Arbitrum'),
              //   href: `https://bridge.arbitrum.io/?l2ChainId=42161`,
              //   type: DropdownMenuItemType.EXTERNAL_LINK,
              // },
              {
                label: t('opBNB Bridge'),
                href: `https://opbnb-bridge.bnbchain.org/deposit`,
                type: DropdownMenuItemType.EXTERNAL_LINK,
              },
            ].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t('Earn'),
      href: '/farms',
      icon: EarnIcon,
      fillIcon: EarnFillIcon,
      image: '/images/decorations/pe2.png',

      items: [
        {
          label: t('Farms'),
          href: '/farms',
          supportChainIds: [ChainId.ARBITRUM, ChainId.CORE, ChainId.BSC, ChainId.BASE],
        },
        {
          label: t('Pools'),
          href: '/pools',
          supportChainIds: [ChainId.ARBITRUM, ChainId.CORE, ChainId.BSC],
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    // {
    //   label: t('Win'),
    //   href: '/prediction',
    //   icon: TrophyIcon,
    //   fillIcon: TrophyFillIcon,
    //   supportChainIds: SUPPORT_ONLY_BSC,
    //   items: [
    //     {
    //       label: t('Trading Competition'),
    //       href: '/competition',
    //       image: '/images/decorations/tc.png',
    //       hideSubNav: true,
    //     },
    //     {
    //       label: t('Prediction (BETA)'),
    //       href: '/prediction',
    //       image: '/images/decorations/prediction.png',
    //     },
    //     {
    //       label: t('Lottery'),
    //       href: '/lottery',
    //       image: '/images/decorations/lottery.png',
    //     },
    //     {
    //       label: t('Pottery (BETA)'),
    //       href: '/pottery',
    //       image: '/images/decorations/lottery.png',
    //     },
    //   ],
    // },
    {
      label: t('NFT'),
      // href: `/nfts`,
      icon: NftIcon,
      fillIcon: NftFillIcon,
      image: '/images/decorations/nft.png',
      // hideSubNav: true,
      showItemsOnMobile: true,
      supportChainIds: [ChainId.ARBITRUM, ChainId.CORE],
      items:
        // chainId === 1116
        //   ? [
        //       {
        //         label: t('Marketplace'),
        //         href: `${nftsBaseUrl}`,
        //       },
        //       {
        //         label: t('Mint'),
        //         href: `${nftsBaseUrl}/mint`,
        //       },
        //       {
        //         label: t('Collection'),
        //         href: `${nftsBaseUrl}/collection`,
        //       },
        //       {
        //         label: t('Incubation'),
        //         href: `${nftsBaseUrl}/incubation`,
        //       },
        //     ]
        //   :
        [
          {
            label: t('Marketplace'),
            href: `${nftsBaseUrl}`,
          },
          {
            label: t('Mint'),
            href: `${nftsBaseUrl}/mint`,
          },
          {
            label: t('Collection'),
            href: `${nftsBaseUrl}/collection`,
          },
          {
            label: t('Collection Baby'),
            href: `${nftsBaseUrl}/baby`,
          },
          {
            label: t('Incubation'),
            href: `${nftsBaseUrl}/incubation`,
            supportChainIds: [ChainId.ARBITRUM, ChainId.CORE],
          },
          {
            label: t('NFTfi'),
            href: `${nftsBaseUrl}/stake`,
            supportChainIds: [ChainId.ARBITRUM],
          },
        ],
    },
    {
      label: t('Info'),
      href: '/',
      icon: MoreIcon,
      hideSubNav: true,
      items: [
        {
          label: t('Auction'),
          href: '/auction',
          supportChainIds: [ChainId.ARBITRUM, ChainId.CORE, ChainId.BSC],
        },
        // {
        //   label: t('Info'),
        //   href: '/info',
        //   supportChainIds: SUPPORT_ONLY_BSC,
        // },
        // {
        //   label: t('IFO'),
        //   href: '/ifo',
        //   supportChainIds: SUPPORT_ONLY_BSC,
        //   image: '/images/ifos/ifo-bunny.png',
        // },
        {
          label: t('IDO'),
          href: '/ido/0x000000000000000000000000000000000000dead',
          // supportChainIds: SUPPORT_ONLY_BSC,
          image: '/images/ifos/ifo-bunny.png',
        },
        // {
        //   label: t('Voting'),
        //   href: '/voting',
        //   supportChainIds: SUPPORT_ONLY_BSC,
        //   image: '/images/voting/voting-bunny.png',
        // },
        // {
        //   type: DropdownMenuItemType.DIVIDER,
        // },
        // {
        //   label: t('Leaderboard'),
        //   href: '/teams',
        //   supportChainIds: SUPPORT_ONLY_BSC,
        //   image: '/images/decorations/leaderboard.png',
        // },
        // {
        //   type: DropdownMenuItemType.DIVIDER,
        // },
        {
          label: t('Docs'),
          href: 'https://lfgswap.gitbook.io/lfgswap/',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
  ].map((item) => addMenuItemSupported(item, chainId))

export default config
