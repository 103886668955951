import { ChainId, Token, WETH } from '@pancakeswap/sdk'
import { LFG_BASE, USDT } from './common'

export const baseTokens = {
  weth: WETH[ChainId.BASE],
  // bnb here points to the wbnb contract. Wherever the currency WETH is required, conditional checks for the symbol 'WETH' can be used
  // lfg: LFG_ZKS,
  // usdc: USDC[ChainId.B],
  usdt: USDT[ChainId.BASE],
  // Cheems: new Token(
  //   ChainId.BASE,
  //   '0xd599dA85F8Fc4877e61f547dFAcffe1238A7149E',
  //   18,
  //   'Cheems',
  //   'Cheems',
  //   'https://www.lfgswap.finance/',
  // ),
  lfg: LFG_BASE,
}
