import { StaticJsonRpcProvider } from '@ethersproject/providers'
import { zks } from '../../packages/wagmi/chains/chains'

export const BSC_PROD_NODE = process.env.NEXT_PUBLIC_NODE_PRODUCTION || 'https://mainnet.ethereumpow.org'

export const bscRpcProvider = new StaticJsonRpcProvider(BSC_PROD_NODE)
export const coreRpcProvider = new StaticJsonRpcProvider('https://rpc.coredao.org')
export const arbRpcProvider = new StaticJsonRpcProvider('https://arb1.arbitrum.io/rpc')
export const zksRpcProvider = new StaticJsonRpcProvider('https://mainnet.era.zksync.io')
export const baseRpcProvider = new StaticJsonRpcProvider('https://developer-access-mainnet.base.org')
export const opBNBRpcProvider = new StaticJsonRpcProvider('https://opbnb-mainnet-rpc.bnbchain.org')

export const getProvider = (chainId: number) => {
  switch (chainId) {
    case 324:
      return zksRpcProvider
    case 1116:
      return coreRpcProvider
    case 10001:
      return bscRpcProvider
    case 42161:
      return arbRpcProvider
    case 8453:
      return baseRpcProvider
    case 204:
      return opBNBRpcProvider
    default:
      return arbRpcProvider
  }
}

export default null
