import { ChainMap, IncubationConf } from './types'

const incubationConf: ChainMap<IncubationConf> = {
  1116: {
    coinName: 'WOW',
    token: '0x699C54156326900c19E189d35D7e460ede256282',
    wallWolf: '0x40F788858FFa9bF3241e93C6Af34179fCe8bb0F8',
    wolfBaby: '0x0A74B9C5D77763F470b62F2264820a31C32a0649',
    wolfBreed: '0x972732F1a79e8947060D1a6Fc09343093e8E8CAB',
    wolfMeta: '0x55d32b85575b63E13c42492874F86279aeC3217b',
  },
  204: {
    coinName: '',
    token: '',
    wallWolf: '',
    wolfBaby: '',
    wolfBreed: '',
    wolfMeta: '',
  },
  1: {
    coinName: '',
    token: '',
    wallWolf: '',
    wolfBaby: '',
    wolfBreed: '',
    wolfMeta: '',
  },
  4: {
    coinName: '',
    token: '',
    wallWolf: '',
    wolfBaby: '',
    wolfBreed: '',
    wolfMeta: '',
  },
  5: {
    coinName: '',
    token: '',
    wallWolf: '',
    wolfBaby: '',
    wolfBreed: '',
    wolfMeta: '',
  },
  10001: {
    coinName: '',
    token: '',
    wallWolf: '',
    wolfBaby: '',
    wolfBreed: '',
    wolfMeta: '',
  },
  42161: {
    coinName: 'WOW',
    token: '0xFd01Cdb7F60Da7BD1A7645e4BCE53a3a3730A3Fe',
    wallWolf: '0xCbc3029FF304F737C0413986548d7D3277D8e50A',
    wolfBaby: '0x99379970c5832f0370645862759F173bE6fC4FEA',
    wolfBreed: '0xA0A00dDe6d6079BcD43c78A0FA8B280Cc19dD124',
    wolfMeta: '0xe448a86EF7070b9cE2af41db1A5c9d91b94E63d2',
  },
  77: {
    coinName: '',
    token: '',
    wallWolf: '',
    wolfBaby: '',
    wolfBreed: '',
    wolfMeta: '',
  },
  324: {
    coinName: '',
    token: '',
    wallWolf: '',
    wolfBaby: '',
    wolfBreed: '',
    wolfMeta: '',
  },
  8453: {
    coinName: '',
    token: '',
    wallWolf: '',
    wolfBaby: '',
    wolfBreed: '',
    wolfMeta: '',
  },
}
export default incubationConf
