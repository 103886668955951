import { ChainId, JSBI, Percent, Token, WNATIVE, WETH } from '@pancakeswap/sdk'
import { BigNumber } from '@ethersproject/bignumber'
import {
  bscTokens,
  bscTestnetTokens,
  USDC,
  USDT,
  coreTokens,
  arbTokens,
  zksTokens,
  baseTokens,
  opBNBTokens,
} from '@pancakeswap/tokens'
import { ChainMap, ChainTokenList } from './types'

export const ROUTER_ADDRESS: ChainMap<string> = {
  [ChainId.ETHEREUM]: '0x3BC722f252C7bAE2f55647e49aDcB9d33Ff6eBcC',
  [ChainId.RINKEBY]: '0x3BC722f252C7bAE2f55647e49aDcB9d33Ff6eBcC',
  [ChainId.GOERLI]: '0x3BC722f252C7bAE2f55647e49aDcB9d33Ff6eBcC',
  [ChainId.BSC]: '0x4f381d5fF61ad1D0eC355fEd2Ac4000eA1e67854',
  [ChainId.CORE]: '0x42a0F91973536f85B06B310fa9C70215784F35a1',
  [ChainId.ARBITRUM]: '0xF83675ac64a142D92234681B7AfB6Ba00fa38dFF',
  [ChainId.BSC_TESTNET]: '0xD99D1c33F9fC3444f8101754aBC46c52416550D1',
  [ChainId.ZKS]: '0x69De6255740461EEC56aBcd9BE8C6C3E541Af54a',
  [ChainId.BASE]: '0xF83675ac64a142D92234681B7AfB6Ba00fa38dFF',
  [ChainId.OPBNB]: '0xF582E251902dBE5F5c87692C04a9Cb5d1c040897',
}

export const VAULT_ROUTER_ADDRESS: ChainMap<string> = {
  [ChainId.ETHEREUM]: '0x3BC722f252C7bAE2f55647e49aDcB9d33Ff6eBcC',
  [ChainId.RINKEBY]: '0x3BC722f252C7bAE2f55647e49aDcB9d33Ff6eBcC',
  [ChainId.GOERLI]: '0x3BC722f252C7bAE2f55647e49aDcB9d33Ff6eBcC',
  [ChainId.BSC]: '0x4f381d5fF61ad1D0eC355fEd2Ac4000eA1e67854',
  [ChainId.CORE]: '0x448A5563627CD2367a9687f65E869A0EFcb0a9cd',
  [ChainId.ARBITRUM]: '0xb71385CBd13bd9E2786F4fC6DE3a7B7F19ADB3bc',
  [ChainId.BSC_TESTNET]: '0xD99D1c33F9fC3444f8101754aBC46c52416550D1',
  [ChainId.ZKS]: '0x69De6255740461EEC56aBcd9BE8C6C3E541Af54a',
  [ChainId.BASE]: '0xb71385CBd13bd9E2786F4fC6DE3a7B7F19ADB3bc',
  [ChainId.OPBNB]: '0xF582E251902dBE5F5c87692C04a9Cb5d1c040897',
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  [ChainId.ETHEREUM]: [
    WNATIVE[ChainId.ETHEREUM],
    USDC[ChainId.ETHEREUM],
    USDT[ChainId.ETHEREUM],
    WETH[ChainId.ETHEREUM],
  ],
  [ChainId.RINKEBY]: [WNATIVE[ChainId.GOERLI], USDC[ChainId.GOERLI], USDT[ChainId.GOERLI]],
  [ChainId.GOERLI]: [WNATIVE[ChainId.RINKEBY], USDC[ChainId.RINKEBY], USDT[ChainId.RINKEBY]],
  [ChainId.BSC]: [bscTokens.weth, bscTokens.lfg, bscTokens.usdt, bscTokens.btcb, bscTokens.eth, bscTokens.usdc],
  [ChainId.CORE]: [coreTokens.weth, coreTokens.lfg, coreTokens.usdt, coreTokens.btc, coreTokens.eth, coreTokens.usdc],
  [ChainId.ARBITRUM]: [
    arbTokens.weth,
    coreTokens.lfg,
    coreTokens.usdt,
    coreTokens.btc,
    coreTokens.eth,
    coreTokens.usdc,
    arbTokens.wow,
    arbTokens.arb,
    arbTokens.lfg,
  ],
  [ChainId.ZKS]: [zksTokens.weth, zksTokens.usdc],
  [ChainId.BASE]: [baseTokens.weth, baseTokens.lfg],
  [ChainId.BSC_TESTNET]: [bscTestnetTokens.wbnb, bscTestnetTokens.cake, bscTestnetTokens.busd],
  [ChainId.OPBNB]: [opBNBTokens.weth, opBNBTokens.lfg],
}

/**
 * Additional bases for specific tokens
 * @example { [WBTC.address]: [renBTC], [renBTC.address]: [WBTC] }
 */
export const ADDITIONAL_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.BSC]: {},
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 * @example [AMPL.address]: [DAI, WNATIVE[ChainId.BSC]]
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.BSC]: {},
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  [ChainId.ETHEREUM]: [USDC[ChainId.ETHEREUM], WETH[ChainId.ETHEREUM], USDT[ChainId.ETHEREUM]],
  [ChainId.RINKEBY]: [USDC[ChainId.RINKEBY], WNATIVE[ChainId.RINKEBY], USDT[ChainId.RINKEBY]],
  [ChainId.GOERLI]: [USDC[ChainId.GOERLI], WNATIVE[ChainId.GOERLI], USDT[ChainId.GOERLI]],
  [ChainId.BSC]: [bscTokens.busd, bscTokens.lfg, bscTokens.weth],
  [ChainId.CORE]: [coreTokens.usdt, coreTokens.lfg, coreTokens.stCore, coreTokens.eth, coreTokens.usdc],
  [ChainId.ARBITRUM]: [arbTokens.usdt, arbTokens.weth, arbTokens.usdc],
  [ChainId.ZKS]: [zksTokens.weth, zksTokens.usdc],
  [ChainId.BSC_TESTNET]: [bscTestnetTokens.wbnb, bscTestnetTokens.cake, bscTestnetTokens.busd],
  [ChainId.BASE]: [baseTokens.weth, baseTokens.lfg, baseTokens.usdt],
  [ChainId.OPBNB]: [opBNBTokens.weth, opBNBTokens.lfg],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  [ChainId.ETHEREUM]: [
    USDC[ChainId.ETHEREUM],
    WNATIVE[ChainId.ETHEREUM],
    USDT[ChainId.ETHEREUM],
    WETH[ChainId.ETHEREUM],
  ],
  [ChainId.RINKEBY]: [USDC[ChainId.RINKEBY], WNATIVE[ChainId.RINKEBY], USDT[ChainId.RINKEBY]],
  [ChainId.GOERLI]: [USDC[ChainId.GOERLI], WNATIVE[ChainId.GOERLI], USDT[ChainId.GOERLI]],
  [ChainId.BSC]: [bscTokens.weth, bscTokens.dai, bscTokens.busd, bscTokens.usdt, bscTokens.lfg],
  [ChainId.CORE]: [coreTokens.weth, coreTokens.usdt, coreTokens.lfg],
  [ChainId.ARBITRUM]: [arbTokens.weth, arbTokens.usdc, arbTokens.usdt, arbTokens.lfg],
  [ChainId.ZKS]: [zksTokens.weth, zksTokens.usdc],
  [ChainId.BSC_TESTNET]: [bscTestnetTokens.wbnb, bscTestnetTokens.cake, bscTestnetTokens.busd],
  [ChainId.BASE]: [baseTokens.weth, baseTokens.lfg],
  [ChainId.OPBNB]: [opBNBTokens.weth],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.ETHEREUM]: [
    [WNATIVE[ChainId.ETHEREUM], USDC[ChainId.ETHEREUM]],
    [WETH[ChainId.ETHEREUM], USDC[ChainId.ETHEREUM]],
    [WETH[ChainId.ETHEREUM], USDT[ChainId.ETHEREUM]],
    [WETH[ChainId.ETHEREUM], WNATIVE[ChainId.ETHEREUM]],
  ],
  [ChainId.BSC]: [
    [bscTokens.lfg, bscTokens.weth],
    [bscTokens.dai, bscTokens.usdt],
  ],
}

export const BIG_INT_ZERO = JSBI.BigInt(0)
export const BIG_INT_TEN = JSBI.BigInt(10)

// one basis point
export const BIPS_BASE = JSBI.BigInt(10000)
export const ONE_BIPS = new Percent(JSBI.BigInt(1), BIPS_BASE)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much WETH so they end up with <.01
export const MIN_BNB: JSBI = JSBI.exponentiate(BIG_INT_TEN, JSBI.BigInt(16)) // .01 WETH
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), BIPS_BASE)

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

export const BASE_FEE = new Percent(JSBI.BigInt(25), BIPS_BASE)
export const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(BASE_FEE)

// WETH
export const DEFAULT_INPUT_CURRENCY = 'WETH'
// LFG
export const DEFAULT_OUTPUT_CURRENCY = '0xFd483e333cBe8fE7A418D9398d6BB81CC2B8e07B'

// Handler string is passed to Gelato to use PCS router
export const GELATO_HANDLER = 'pancakeswap'
export const GENERIC_GAS_LIMIT_ORDER_EXECUTION = BigNumber.from(500000)

export const LIMIT_ORDERS_DOCS_URL = 'https://docs.lfgswap.finance/products/pancakeswap-exchange/limit-orders'
