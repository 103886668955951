import { ChainId, Token } from '@pancakeswap/sdk'

export const LFG_MAINNET = new Token(
  ChainId.BSC,
  '0xFd483e333cBe8fE7A418D9398d6BB81CC2B8e07B',
  18,
  'LFG',
  'LFGSwap Token',
  'https://lfgswap.finance/',
)
export const LFG_OPBNB = new Token(
  ChainId.OPBNB,
  '0xE5552e0318531F9Ec585c83bDc8956C08Bf74b71',
  18,
  'LFG',
  'LFGSwap Token',
  'https://lfgswap.finance/',
)

export const LFG_TEST = new Token(
  ChainId.ARBITRUM,
  '0x595915E6dA13d21Eaa708FbBef85144c24892424',
  18,
  'LFG',
  'LFGSwap Token',
  'https://lfgswap.finance/',
)

export const LFG_BASE = new Token(
  ChainId.BASE,
  '0xF7a0b80681eC935d6dd9f3Af9826E68B99897d6D',
  18,
  'LFG',
  'LFGSwap Token',
  'https://lfgswap.finance/',
)
export const LFG_CORE = new Token(
  ChainId.CORE,
  '0xF7a0b80681eC935d6dd9f3Af9826E68B99897d6D',
  18,
  'LFG',
  'LFGSwap Token',
  'https://lfgswap.finance/',
)

export const LFG_ARB = new Token(
  ChainId.ARBITRUM,
  '0xF7a0b80681eC935d6dd9f3Af9826E68B99897d6D',
  18,
  'LFG',
  'LFGSwap Token',
  'https://lfgswap.finance/',
)

export const CONVERTVAULT_CORE = new Token(
  ChainId.CORE,
  '0x20c29789ea1017b3688897fCe29576Ed4083Ed29',
  18,
  'LFG_Vault',
  'LFGSwap Token',
  'https://lfgswap.finance/',
)

export const CAKE_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0xFa60D973F7642B748046464e165A65B7323b0DEE',
  18,
  'LFG',
  'LFGSwap Token',
  'https://lfgswap.finance/',
)

export const USDC_BSC = new Token(
  ChainId.BSC,
  '0x25DE68ef588cb0c2c8F3537861E828Ae699CD0DB',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0x64544969ed7EBf5f083679233325356EbE738930',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_ETH = new Token(ChainId.ETHEREUM, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 6, 'USDC', 'USD Coin')

export const USDC_RINKEBY = new Token(
  ChainId.RINKEBY,
  '0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b',
  6,
  'tUSDC',
  'test USD Coin',
)

export const USDC_GOERLI = new Token(
  ChainId.GOERLI,
  '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
  6,
  'tUSDC',
  'test USD Coin',
)

export const USDT_BSC = new Token(
  ChainId.BSC,
  '0x2AD7868CA212135C6119FD7AD1Ce51CFc5702892',
  6,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_TEST = new Token(
  ChainId.ARBITRUM,
  '0xdcb4C95D8A5F07ab4a6a1FBB680555c14086dd36',
  6,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_CORE = new Token(
  ChainId.CORE,
  '0x9Ebab27608bD64AFf36f027049aECC69102a0D1e',
  6,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_BASE = new Token(
  ChainId.BASE,
  '0xfe668a3d6f05e7799aae04659fc274ac00d094c0',
  6,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)
export const USDT_ARB = new Token(
  ChainId.ARBITRUM,
  '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
  6,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)
// export const USDT_ZKS = new Token(
//   ChainId.ZKS,
//   '',
//   6,
//   'USDT',
//   'Tether USD',
//   'https://tether.to/',
// )
export const USDC_CORE = new Token(
  ChainId.CORE,
  '0x3fCa6743e2fB55759FeE767f3a68B2c06D699dc4',
  6,
  'USDC',
  'USDC',
  'https://www.centre.io/usdc',
)

export const USDC_ARB = new Token(
  ChainId.ARBITRUM,
  '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
  6,
  'USDC',
  'USDC',
  'https://www.centre.io/usdc',
)
export const USDC_ZKS = new Token(
  ChainId.ZKS,
  '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
  6,
  'USDC',
  'USDC',
  'https://www.centre.io/usdc',
)

export const USDT_ETH = new Token(
  ChainId.ETHEREUM,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  6,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const BUSD_MAINNET = new Token(
  ChainId.BSC,
  '0xf61eb8999f2F222f425d41dA4C2ff4b6D8320C87',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0x8516Fc284AEEaa0374E66037BD2309349FF728eA',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_ETH = new Token(
  ChainId.ETHEREUM,
  '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_RINKEBY = new Token(
  ChainId.RINKEBY,
  '0x4e2442A6f7AeCE64Ca33d31756B5390860BF973E',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_GOERLI = new Token(
  ChainId.GOERLI,
  '0xb809b9B2dc5e93CB863176Ea2D565425B03c0540',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const USDT = {
  [ChainId.ETHEREUM]: BUSD_ETH,
  [ChainId.RINKEBY]: BUSD_RINKEBY,
  [ChainId.GOERLI]: BUSD_GOERLI,
  [ChainId.BSC]: USDT_BSC,
  [ChainId.BSC_TESTNET]: BUSD_TESTNET,
  [ChainId.CORE]: USDT_CORE,
  [ChainId.ARBITRUM]: USDT_ARB,
  // [ChainId.ZKS]: USDT_ZKS
  [ChainId.BASE]: USDT_BASE,
  // [ChainId.OPBNB]:
}

export const LFG = {
  [ChainId.BSC]: LFG_MAINNET,
  [ChainId.BSC_TESTNET]: CAKE_TESTNET,
  [ChainId.CORE]: LFG_CORE,
  [ChainId.ARBITRUM]: LFG_ARB,
  [ChainId.BASE]: LFG_BASE,
  [ChainId.OPBNB]: LFG_OPBNB,
}

export const ConvertVault = {
  [ChainId.CORE]: CONVERTVAULT_CORE,
}

export const USDC = {
  [ChainId.BSC]: USDC_BSC,
  [ChainId.BSC_TESTNET]: USDC_TESTNET,
  [ChainId.ETHEREUM]: USDC_ETH,
  [ChainId.RINKEBY]: USDC_RINKEBY,
  [ChainId.GOERLI]: USDC_GOERLI,
  [ChainId.CORE]: USDC_CORE,
  [ChainId.ARBITRUM]: USDC_ARB,
  [ChainId.ZKS]: USDC_ZKS,
}
