import BigNumber from 'bignumber.js'
import { multicallv2 } from 'utils/multicall'
import cakeVaultAbi from 'config/abi/cakeVaultV2.json'
// import { getCakeVaultAddress, getCakeFlexibleSideVaultAddress } from 'utils/addressHelpers'
import { getCakeVaultAddress } from 'utils/addressHelpers'
import { BIG_ZERO } from 'utils/bigNumber'
import { getCakeContract } from 'utils/contractHelpers'
import { ChainId } from '../../../packages/swap-sdk/src/constants'

// const cakeFlexibleSideVaultV2 = getCakeFlexibleSideVaultAddress()

export const fetchPublicVaultData = async (chainId: number) => {
  if (!chainId)
    return {
      totalShares: null,
      totalLockedAmount: null,
      pricePerFullShare: null,
      totalCakeInVault: null,
    }
  const cakeVaultV2 = getCakeVaultAddress(chainId)

  try {
    const calls = ['getPricePerFullShare', 'totalShares', 'totalLockedAmount'].map((method) => ({
      address: cakeVaultV2,
      name: method,
    }))
    const cakeContract = getCakeContract(chainId)

    const [[[sharePrice], [shares], totalLockedAmount], totalCakeInVault] = await Promise.all([
      multicallv2({
        abi: cakeVaultAbi,
        calls,
        chainId,
        options: {
          requireSuccess: false,
        },
      }),
      cakeContract.balanceOf(cakeVaultV2),
    ])

    const totalSharesAsBigNumber = shares ? new BigNumber(shares.toString()) : BIG_ZERO
    const totalLockedAmountAsBigNumber = totalLockedAmount ? new BigNumber(totalLockedAmount[0].toString()) : BIG_ZERO
    const sharePriceAsBigNumber = sharePrice ? new BigNumber(sharePrice.toString()) : BIG_ZERO
    return {
      totalShares: totalSharesAsBigNumber.toJSON(),
      totalLockedAmount: totalLockedAmountAsBigNumber.toJSON(),
      pricePerFullShare: sharePriceAsBigNumber.toJSON(),
      totalCakeInVault: new BigNumber(totalCakeInVault.toString()).toJSON(),
    }
  } catch (error) {
    console.log(error)
    return {
      totalShares: null,
      totalLockedAmount: null,
      pricePerFullShare: null,
      totalCakeInVault: null,
    }
  }
}

// export const fetchPublicFlexibleSideVaultData = async (cakeVaultAddress) => {
//   try {
//     const calls = ['getPricePerFullShare', 'totalShares'].map((method) => ({
//       address: cakeVaultAddress,
//       name: method,
//     }))

//     const [[[sharePrice], [shares]], totalCakeInVault] = await Promise.all([
//       multicallv2({
//         abi: cakeVaultAbi,
//         calls,
//         options: { requireSuccess: false },
//       }),
//       cakeContract.balanceOf(cakeVaultAddress),
//     ])

//     const totalSharesAsBigNumber = shares ? new BigNumber(shares.toString()) : BIG_ZERO
//     const sharePriceAsBigNumber = sharePrice ? new BigNumber(sharePrice.toString()) : BIG_ZERO
//     return {
//       totalShares: totalSharesAsBigNumber.toJSON(),
//       pricePerFullShare: sharePriceAsBigNumber.toJSON(),
//       totalCakeInVault: new BigNumber(totalCakeInVault.toString()).toJSON(),
//     }
//   } catch (error) {
//     return {
//       totalShares: null,
//       pricePerFullShare: null,
//       totalCakeInVault: null,
//     }
//   }
// }

export const fetchVaultFees = async (chainId: number) => {
  if (!chainId)
    return {
      performanceFee: null,
      withdrawalFee: null,
      withdrawalFeePeriod: null,
    }
  const cakeVaultV2 = getCakeVaultAddress(chainId)

  try {
    const calls = ['performanceFee', 'withdrawFee', 'withdrawFeePeriod'].map((method) => ({
      address: cakeVaultV2,
      name: method,
    }))

    const [[performanceFee], [withdrawalFee], [withdrawalFeePeriod]] = await multicallv2({
      abi: cakeVaultAbi,
      calls,
      chainId,
    })

    return {
      performanceFee: performanceFee.toNumber(),
      withdrawalFee: withdrawalFee.toNumber(),
      withdrawalFeePeriod: withdrawalFeePeriod.toNumber(),
    }
  } catch (error) {
    return {
      performanceFee: null,
      withdrawalFee: null,
      withdrawalFeePeriod: null,
    }
  }
}

export default fetchPublicVaultData
