import { getAddress } from '@ethersproject/address'
import { ChainId, Token, WETH } from '@pancakeswap/sdk'
import { LFG_CORE, LFG_TEST } from './common'

// console.log(getAddress('0x751669f3dce1ed1c449de44889365e40f13ce57f'))
export const coreTokens = {
  weth: WETH[ChainId.CORE],
  // bnb here points to the wbnb contract. Wherever the currency WETH is required, conditional checks for the symbol 'WETH' can be used
  lfg: LFG_CORE,
  lfg_receit: new Token(ChainId.CORE, '0x6546CBe3eBeFDf15f0D2B4eA923199D2FEE934DB', 18, 'LFG(ARB)', 'LFG(ARB)', ''),
  lfg_receit_base: new Token(
    ChainId.CORE,
    '0x2390741624ffb3F63305c405a34D3d9A850F9e93',
    18,
    'LFG(BASE)',
    'LFG(BASE)',
    '',
  ),

  lfg_test: LFG_TEST,
  usdc: new Token(
    ChainId.CORE,
    '0x3fCa6743e2fB55759FeE767f3a68B2c06D699dc4',
    6,
    'USDC',
    'USDC',
    'https://www.binance.com/',
  ),
  usdt: new Token(
    ChainId.CORE,
    '0x9Ebab27608bD64AFf36f027049aECC69102a0D1e',
    6,
    'USDT',
    'USDT',
    'https://www.binance.com/',
  ),
  usdt_test: new Token(
    ChainId.CORE,
    '0xf66cef53c518659bFA0A9a4Aa07445AF08bf9B3a',
    18,
    'USDT',
    'USDT',
    'https://www.binance.com/',
  ),
  eth: new Token(
    ChainId.CORE,
    '0x15dC8168AA893ee5ABf46778AE99Fd7Ed9225914',
    18,
    'ETH',
    'ETH',
    'https://www.binance.com/',
  ),
  btc: new Token(
    ChainId.CORE,
    '0xe4610F0194a82F23c82b07415330FC4ee9282b5B',
    18,
    'BTC',
    'BTC',
    'https://www.binance.com/',
  ),
  mCore: new Token(
    ChainId.CORE,
    '0xF83675ac64a142D92234681B7AfB6Ba00fa38dFF',
    18,
    'mCore',
    'mCore',
    'https://www.binance.com/',
  ),
  nuwa: new Token(
    ChainId.CORE,
    '0xCEBaE70ABBc91019CAcAb7a299e3CCa0b96f7dd7',
    18,
    'NUWA',
    'NUWA',
    'https://www.binance.com/',
  ),
  coreShib: new Token(
    ChainId.CORE,
    '0x751669F3dCE1ED1c449dE44889365E40F13ce57f',
    9,
    'COREShib',
    'COREShib',
    'https://www.binance.com/',
  ),
  fry: new Token(
    ChainId.CORE,
    '0x1C5926792D7BC71cD7f3A08Ee9Cb93D949f0391d',
    18,
    'FRY',
    'FRY',
    'https://www.binance.com/',
  ),
  happy: new Token(
    ChainId.CORE,
    '0xAc9B3614Dd28c4ca72853CA996Ab76F03Db73Fb4',
    18,
    'HAPPY',
    'HAPPY',
    'https://www.binance.com/',
  ),
  cid: new Token(
    ChainId.CORE,
    '0x000000000E1D682Cc39ABe9B32285fdEa1255374',
    18,
    'CORE ID',
    'CID',
    'https://www.binance.com/',
  ),
  cdo: new Token(
    ChainId.CORE,
    '0x688c57B76f5F724C1ABEec0adEbe20420f11B804',
    18,
    'CDO',
    'Cido Finance Token',
    'https://www.cido.finance/',
  ),
  lfg_core: new Token(
    ChainId.CORE,
    '0x7E2ceeB29aa40106D46985c776B45D9584DC2412',
    18,
    'LFG-CORE LP',
    'LFG-CORE LP',
    'https://www.cido.finance/',
  ),
  stCore: new Token(
    ChainId.CORE,
    '0xc4aa48995f2BB9CFaB26D82565Baf569007d867F',
    18,
    'stCore',
    'stCore',
    'https://www.cido.finance/',
  ),
  wow: new Token(
    ChainId.CORE,
    '0x699C54156326900c19E189d35D7e460ede256282',
    18,
    'WOW',
    'WOW',
    'https://www.lfgswap.finance/',
  ),
}
