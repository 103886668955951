import { useWeb3React } from '@pancakeswap/wagmi'
import BigNumber from 'bignumber.js'
import { bscTokens, coreTokens, LFG, opBNBTokens } from '@pancakeswap/tokens'
import { FAST_INTERVAL, SLOW_INTERVAL } from 'config/constants'
import { BigNumber as EthersBigNumber } from '@ethersproject/bignumber'
import { Zero } from '@ethersproject/constants'
import { ChainId } from '@pancakeswap/sdk'
import { useMemo } from 'react'
import useSWR from 'swr'
import { BIG_ZERO } from 'utils/bigNumber'
import { bscRpcProvider, coreRpcProvider, opBNBRpcProvider } from 'utils/providers'
import { useTokenContract } from './useContract'
import { useSWRContract } from './useSWRContract'

const useTokenBalance = (tokenAddress: string, forceBSC?: boolean) => {
  const { account } = useWeb3React()

  const contract = useTokenContract(tokenAddress, false)

  const key = useMemo(
    () =>
      account
        ? {
            contract: forceBSC ? contract.connect(bscRpcProvider) : contract,
            methodName: 'balanceOf',
            params: [account],
          }
        : null,
    [account, contract, forceBSC],
  )

  const { data, status, ...rest } = useSWRContract(key as any, {
    refreshInterval: FAST_INTERVAL,
  })

  return {
    ...rest,
    fetchStatus: status,
    balance: data ? new BigNumber(data.toString()) : BIG_ZERO,
  }
}

export const useTokenAccountBalance = (tokenAddress: string, userAddress?: string) => {
  const contract = useTokenContract(tokenAddress, false)

  const key = useMemo(
    () =>
      userAddress
        ? {
            contract,
            methodName: 'balanceOf',
            params: [userAddress],
          }
        : null,
    [userAddress, contract],
  )

  const { data, status, ...rest } = useSWRContract(key as any, {
    refreshInterval: SLOW_INTERVAL,
  })

  return {
    ...rest,
    fetchStatus: status,
    balance: data ? new BigNumber(data.toString()) : BIG_ZERO,
  }
}

export const useTokenTotalSupply = (tokenAddress: string) => {
  const { account } = useWeb3React()

  const contract = useTokenContract(tokenAddress, false)

  const key = useMemo(
    () =>
      account
        ? {
            contract,
            methodName: 'totalSupply',
          }
        : null,
    [account, contract],
  )

  const { data, status, ...rest } = useSWRContract(key as any, {
    refreshInterval: SLOW_INTERVAL,
  })

  return {
    ...rest,
    fetchStatus: status,
    totalSupply: data ? new BigNumber(data.toString()) : BIG_ZERO,
  }
}

export const useGetBnbBalance = (chainId?) => {
  const { account } = useWeb3React()
  const { status, data, mutate } = useSWR(
    [account, 'bnbBalance'],
    async () => {
      let bal
      if (chainId === 1116) {
        bal = await coreRpcProvider.getBalance(account)
      } else if (chainId === 204) {
        bal = await opBNBRpcProvider.getBalance(account)
      } else bal = await bscRpcProvider.getBalance(account)
      return bal
    },
    {
      refreshInterval: FAST_INTERVAL,
    },
  )

  return { balance: data ? new BigNumber(data.toString()) : BIG_ZERO, fetchStatus: status, refresh: mutate }
}

export const useGetBalance = (currency, chainId?) => {
  const bnbBalance = useGetBnbBalance(chainId)
  const tokenBalance = useTokenBalance(currency.address)
  let balance
  if (
    currency === bscTokens.weth ||
    currency === coreTokens.weth ||
    currency === opBNBTokens.weth ||
    currency === opBNBTokens.bnb
  ) {
    balance = bnbBalance
  } else {
    balance = tokenBalance
  }
  return balance
}

export const useGetCakeBalance = () => {
  const { chainId } = useWeb3React()
  const { balance, fetchStatus } = useTokenBalance(LFG[chainId]?.address || LFG[ChainId.CORE]?.address, true)

  // TODO: Remove ethers conversion once useTokenBalance is converted to ethers.BigNumber
  return { balance: EthersBigNumber.from(balance.toString()), fetchStatus }
}

export default useTokenBalance
