import { Token, ChainId } from '@pancakeswap/sdk'
import { arbTokens, baseTokens, bscTokens, coreTokens, opBNBTokens } from '@pancakeswap/tokens'
import { CAKE_BNB_LP_MAINNET } from './lp'
import { Ifo } from './types'

export const cakeBnbLpToken = new Token(ChainId.BSC, CAKE_BNB_LP_MAINNET, 18, 'LFG-WETH LP')

const idos: Ifo[] = [
  // {
  //   id: 'lfg',
  //   address: '0x45E4F95512328169298602ACFc748dA47C371c07',
  //   isActive: true,
  //   name: 'lfg',
  //   poolBasic: {
  //     saleAmount: '30,000,000 LFG',
  //     raiseAmount: '900,000 USDT',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.03,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '25,200,000 LFG',
  //     raiseAmount: '$1,890,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.9,
  //   },
  //   currency: bscTokens.usdt,
  //   token: bscTokens.lfg,
  //   releaseBlockNumber: 20685666,
  //   campaignId: '511400000',
  //   articleUrl: 'https://lfgswap.finance/voting/proposal/bafkreieqv7mbzmumyftstt6l32x6okfzq4syrea7k5zbqgohhcekcvbduu',
  //   tokenOfferingPrice: 0.03,
  //   version: 3.2,
  //   twitterUrl: 'https://twitter.com/WombatExchange',
  //   description: 'Wombat Exchange is a next generation multi-chain stableswap native to WETH Chain.',
  // },
  // {
  //   id: 'lfg',
  //   address: '0x3AFfA2EE441F44031115080905700aB9b7732fBF',
  //   isActive: false,
  //   name: 'lfg',
  //   poolBasic: {
  //     saleAmount: '30,000,000 LFG',
  //     raiseAmount: '900,000 USDT',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.03,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '25,200,000 LFG',
  //     raiseAmount: '$1,890,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.9,
  //   },
  //   currency: bscTokens.usdt,
  //   token: bscTokens.lfg,
  //   releaseBlockNumber: 20685666,
  //   campaignId: '511400000',
  //   articleUrl: 'https://lfgswap.finance/voting/proposal/bafkreieqv7mbzmumyftstt6l32x6okfzq4syrea7k5zbqgohhcekcvbduu',
  //   tokenOfferingPrice: 0.03,
  //   version: 3.2,
  //   twitterUrl: 'https://twitter.com/WombatExchange',
  //   description: 'Wombat Exchange is a next generation multi-chain stableswap native to WETH Chain.',
  // },
  // {
  //   id: 'ethwid',
  //   address: '0xCbc3029FF304F737C0413986548d7D3277D8e50A',
  //   isActive: false,
  //   name: 'ethwid',
  //   poolBasic: {
  //     saleAmount: '20,000,000 EID',
  //     raiseAmount: '1,666,666 LFG',
  //     cakeToBurn: '499,999.8 LFG (30%)',
  //     distributionRatio: 0.2,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '25,200,000 LFG',
  //     raiseAmount: '$1,890,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.9,
  //   },
  //   currency: bscTokens.lfg,
  //   token: bscTokens.eid,
  //   releaseBlockNumber: 20685666,
  //   campaignId: '511400000',
  //   articleUrl: 'https://lfgswap.finance/voting/proposal/bafkreieqv7mbzmumyftstt6l32x6okfzq4syrea7k5zbqgohhcekcvbduu',
  //   tokenOfferingPrice: 0.083,
  //   version: 3.2,
  //   twitterUrl: 'https://twitter.com/WombatExchange',
  //   description: 'Wombat Exchange is a next generation multi-chain stableswap native to WETH Chain.',
  // },
  // {
  //   id: 'BoneW',
  //   address: '0xD6329c736572530B3E556792FC06fEf140017ea2',
  //   isActive: false,
  //   name: 'BoneW',
  //   poolBasic: {
  //     saleAmount: '40,000,000 BoneW',
  //     raiseAmount: '2000 ETHW',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.2,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '25,200,000 LFG',
  //     raiseAmount: '$1,890,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.9,
  //   },
  //   currency: bscTokens.weth,
  //   token: bscTokens.boneW,
  //   releaseBlockNumber: 20685666,
  //   campaignId: '511400000',
  //   articleUrl: 'https://lfgswap.finance/voting/proposal/bafkreieqv7mbzmumyftstt6l32x6okfzq4syrea7k5zbqgohhcekcvbduu',
  //   tokenOfferingPrice: 0.00005,
  //   version: 3.2,
  //   twitterUrl: 'https://twitter.com/WombatExchange',
  //   description: 'Wombat Exchange is a next generation multi-chain stableswap native to WETH Chain.',
  // },
  // {
  //   id: 'ABG',
  //   address: '0x2f69620780154aBDfe26B99a91BDe80EC1432631',
  //   isActive: true,
  //   name: 'ABG',
  //   poolBasic: {
  //     saleAmount: '168,000,000 ABG',
  //     raiseAmount: '15,750,000 LFG',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.05,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '25,200,000 LFG',
  //     raiseAmount: '$1,890,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.9,
  //   },
  //   currency: bscTokens.lfg,
  //   token: bscTokens.abg,
  //   releaseBlockNumber: 20685666,
  //   campaignId: '511400000',
  //   articleUrl: 'https://lfgswap.finance/voting/proposal/bafkreieqv7mbzmumyftstt6l32x6okfzq4syrea7k5zbqgohhcekcvbduu',
  //   tokenOfferingPrice: 0.09375,
  //   version: 3.2,
  //   twitterUrl: 'https://twitter.com/WombatExchange',
  //   description: 'Wombat Exchange is a next generation multi-chain stableswap native to WETH Chain.',
  // },
  // {
  //   id: 'LFGCORE',
  //   address: '0xbb715427B0f50e5b5463839488a4858A9713a604',
  //   isActive: false,
  //   name: 'LFG',
  //   poolBasic: {
  //     saleAmount: '25,000,000 LFG',
  //     raiseAmount: '25,000 CORE',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.05,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '25,200,000 LFG',
  //     raiseAmount: '$1,890,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.9,
  //   },
  //   currency: coreTokens.weth,
  //   token: coreTokens.lfg,
  //   releaseBlockNumber: 20685666,
  //   campaignId: '511400000',
  //   articleUrl: 'https://lfgswap.finance/voting/proposal/bafkreieqv7mbzmumyftstt6l32x6okfzq4syrea7k5zbqgohhcekcvbduu',
  //   tokenOfferingPrice: 0.001,
  //   version: 3.2,
  //   twitterUrl: 'https://twitter.com/WombatExchange',
  //   description: 'Wombat Exchange is a next generation multi-chain stableswap native to WETH Chain.',
  // },
  // {
  //   id: 'FRY',
  //   address: '0x018f5BA1388e521B321CAe9e76EE1638b0390678',
  //   isActive: false,
  //   name: 'FRY',
  //   poolBasic: {
  //     saleAmount: '10,000,000,000 FRY',
  //     raiseAmount: '3,000,000 LFG',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.1,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '25,200,000 LFG',
  //     raiseAmount: '$1,890,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.9,
  //   },
  //   currency: coreTokens.lfg,
  //   token: coreTokens.fry,
  //   reward: coreTokens.happy,
  //   releaseBlockNumber: 20685666,
  //   campaignId: '511400000',
  //   articleUrl: 'https://lfgswap.finance/voting/proposal/bafkreieqv7mbzmumyftstt6l32x6okfzq4syrea7k5zbqgohhcekcvbduu',
  //   tokenOfferingPrice: 0.0003,
  //   version: 3.2,
  //   twitterUrl: 'https://twitter.com/WombatExchange',
  //   description: 'Wombat Exchange is a next generation multi-chain stableswap native to WETH Chain.',
  // },
  // {
  //   id: 'Happy',
  //   address: '0x481ecD0B77fC2ff4e8271FdB45C2B72AF271579C',
  //   isActive: false,
  //   name: 'Happy',
  //   poolBasic: {
  //     saleAmount: '10,000,000 Happy',
  //     raiseAmount: '3,000,000 LFG',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.1,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '25,200,000 LFG',
  //     raiseAmount: '$1,890,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.9,
  //   },
  //   currency: coreTokens.lfg,
  //   token: coreTokens.happy,
  //   reward: coreTokens.happy,
  //   releaseBlockNumber: 20685666,
  //   campaignId: '511400000',
  //   articleUrl: 'https://lfgswap.finance/voting/proposal/bafkreieqv7mbzmumyftstt6l32x6okfzq4syrea7k5zbqgohhcekcvbduu',
  //   tokenOfferingPrice: 0.3,
  //   version: 3.2,
  //   twitterUrl: 'https://twitter.com/WombatExchange',
  //   description: 'Wombat Exchange is a next generation multi-chain stableswap native to WETH Chain.',
  // },
  {
    id: 'Cido',
    address: '0x8A899BA70625b9bb884752D5EE0348DC57Ee2D8c',
    isActive: false,
    name: 'Cido',
    poolBasic: {
      saleAmount: '100,000,000 CDO',
      raiseAmount: '≈700000 USD, LFG-CORE LP',
      cakeToBurn: '$0',
      distributionRatio: 0.1,
    },
    poolUnlimited: {
      saleAmount: '25,200,000 LFG',
      raiseAmount: '$1,890,000',
      cakeToBurn: '$0',
      distributionRatio: 0.9,
    },
    currency: coreTokens.lfg_core,
    token: coreTokens.cdo,
    reward: coreTokens.cdo,
    releaseBlockNumber: 20685666,
    campaignId: '511400000',
    articleUrl: 'https://medium.com/@CidoFinance/cido-finance-initial-farm-offering-b2ae4f8e4c8a',
    tokenOfferingPrice: 0.007,
    version: 3.2,
    twitterUrl: 'https://twitter.com/CidoFinance',
    telegramUrl: 'https://t.me/CidoFinance',
    description: 'Wombat Exchange is a next generation multi-chain stableswap native to WETH Chain.',
  },
  {
    id: 'ETHW—LFG—ARB',
    address: '0x9f17De17c3930Fc8c9e2aDb5649D6eE95619Add4',
    isActive: false,
    name: 'ETHW—LFG—ARB',
    poolBasic: {
      saleAmount: '100,000 LFG(ARB)',
      raiseAmount: '100,000 LFG',
      cakeToBurn: '$0',
      distributionRatio: 0.1,
    },
    poolUnlimited: {
      saleAmount: '25,200,000 LFG',
      raiseAmount: '$1,890,000',
      cakeToBurn: '$0',
      distributionRatio: 0.9,
    },
    currency: bscTokens.lfg,
    token: bscTokens.lfg_receit,
    reward: bscTokens.lfg_receit,
    releaseBlockNumber: 20685666,
    campaignId: '511400000',
    articleUrl: 'https://medium.com/@lfgswap/lfgswap-enters-the-wonderland-called-arbitrum-69390d31c51f',
    tokenOfferingPrice: 1,
    version: 3.2,
  },
  {
    id: 'CORE—LFG—ARB',
    address: '0xe2b1aAc29A769909071f1c304cdBfBE09399B078',
    isActive: false,
    name: 'CORE—LFG—ARB',
    poolBasic: {
      saleAmount: '2,900,000 LFG(ARB)',
      raiseAmount: '100,000 LFG',
      cakeToBurn: '$0',
      distributionRatio: 0.1,
    },
    poolUnlimited: {
      saleAmount: '25,200,000 LFG',
      raiseAmount: '$1,890,000',
      cakeToBurn: '$0',
      distributionRatio: 0.9,
    },
    currency: coreTokens.lfg,
    token: coreTokens.lfg_receit,
    reward: coreTokens.lfg_receit,
    releaseBlockNumber: 20685666,
    campaignId: '511400000',
    articleUrl: 'https://medium.com/@lfgswap/lfgswap-enters-the-wonderland-called-arbitrum-69390d31c51f',
    tokenOfferingPrice: 1,
    version: 3.2,
  },
  {
    id: 'LFG-ARB',
    address: '0x3379998e4748CD8694770794D9420E2Dcf896F8a',
    isActive: false,
    name: 'LFG-ARB',
    poolBasic: {
      saleAmount: '21,000,000 LFG',
      raiseAmount: '630,000 USDT',
      cakeToBurn: '$0',
      distributionRatio: 0.1,
    },
    poolUnlimited: {
      saleAmount: '25,200,000 LFG',
      raiseAmount: '$1,890,000',
      cakeToBurn: '$0',
      distributionRatio: 0.9,
    },
    currency: arbTokens.usdt_test,
    token: arbTokens.lfg,
    reward: arbTokens.lfg,
    releaseBlockNumber: 20685666,
    campaignId: '511400000',
    articleUrl: 'https://medium.com/@lfgswap/lfgswap-ido-on-arbitrum-a0004d71667b',
    twitterUrl: 'https://twitter.com/LfgSwap',
    telegramUrl: 'https://t.me/LFGSWAPFINANCE',
    tokenOfferingPrice: 0.03,
    version: 3.2,
  },
  {
    id: 'WOW',
    address: '0x1263fB2cf12719e4aA6E2dFbc26A90e0F2C6089C',
    isActive: false,
    name: 'WOW',
    poolBasic: {
      saleAmount: '500,000,000 WOW',
      raiseAmount: '2,700,000 LFG',
      cakeToBurn: '$0',
      distributionRatio: 0.1,
    },
    poolUnlimited: {
      saleAmount: '25,200,000 LFG',
      raiseAmount: '$1,890,000',
      cakeToBurn: '$0',
      distributionRatio: 0.9,
    },
    currency: arbTokens.lfg,
    token: arbTokens.wow,
    reward: arbTokens.wow,
    releaseBlockNumber: 20685666,
    campaignId: '511400000',
    articleUrl: 'https://medium.com/@lfgswap/d86a25af890f',
    twitterUrl: 'https://twitter.com/LfgSwap',
    tokenOfferingPrice: 0.0054,
    version: 3.2,
  },
  {
    id: 'MTS',
    address: '0xc00c0c0092244a211530B2759c8bAEDab381A4f4',
    isActive: false,
    name: 'MTS',
    poolBasic: {
      saleAmount: '5,000,000 MTS',
      raiseAmount: '100,000 USDT',
      cakeToBurn: '$0',
      distributionRatio: 0.1,
    },
    poolUnlimited: {
      saleAmount: '25,200,000 LFG',
      raiseAmount: '$1,890,000',
      cakeToBurn: '$0',
      distributionRatio: 0.9,
    },
    currency: arbTokens.usdt,
    token: arbTokens.mts,
    reward: arbTokens.mts,
    releaseBlockNumber: 20685666,
    campaignId: '511400000',
    articleUrl: 'https://medium.com/@lfgswap/lfgswap-launches-new-ido-for-moontouch-token-mt-df9fbb613450',
    twitterUrl: 'https://twitter.com/MoonTouch77',
    discordUrl: 'https://discord.com/invite/moontouch',
    tokenOfferingPrice: 0.02,
    version: 3.2,
  },
  // {
  //   id: 'IDO_USDT_HOME',
  //   address: '0x30aff15383c5B944aD918CeE84687EC99594aA0C',
  //   isActive: false,
  //   name: 'HOME',
  //   poolBasic: {
  //     saleAmount: '600,000,000 HOME',
  //     raiseAmount: '300,000 USDT',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.1,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '25,200,000 LFG',
  //     raiseAmount: '$1,890,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.9,
  //   },
  //   currency: arbTokens.usdt,
  //   token: arbTokens.home,
  //   reward: arbTokens.home,
  //   releaseBlockNumber: 20685666,
  //   campaignId: '511400000',
  //   articleUrl: 'https://medium.com/@otterhome/unlocking-decentralized-finance-otterhome-ido-on-lfgswap-43e3835916a6',
  //   twitterUrl: 'https://twitter.com/Otter_home',
  //   telegramUrl: 'https://t.me/Otterhome_global',
  //   tokenOfferingPrice: 0.0005,
  //   version: 3.2,
  // },

  {
    id: 'ETHW—LFG—BASE',
    address: '0xD5696b6bD66010cfd18415f820E5721F326feD3E',
    isActive: false,
    name: 'ETHW—LFG—BASE',
    poolBasic: {
      saleAmount: '2,300,000 LFG(BASE)',
      raiseAmount: '2,300,000 LFG',
      cakeToBurn: '$0',
      distributionRatio: 0.1,
    },
    poolUnlimited: {
      saleAmount: '25,200,000 LFG',
      raiseAmount: '$1,890,000',
      cakeToBurn: '$0',
      distributionRatio: 0.9,
    },
    currency: bscTokens.lfg,
    token: bscTokens.lfg_receit,
    reward: bscTokens.lfg_receit,
    releaseBlockNumber: 20685666,
    campaignId: '511400000',
    articleUrl: 'https://medium.com/@lfgswap/lfgswap-enters-the-wonderland-called-arbitrum-69390d31c51f',
    tokenOfferingPrice: 1,
    version: 3.2,
  },
  {
    id: 'CORE—LFG—BASE',
    address: '0xb364743f0fdD488b51c285ea2C8033DB5f1FeE62',
    isActive: false,
    name: 'CORE—LFG—BASE',
    poolBasic: {
      saleAmount: '1,500,000 LFG(BASE)',
      raiseAmount: '1,500,000 LFG',
      cakeToBurn: '$0',
      distributionRatio: 0.1,
    },
    poolUnlimited: {
      saleAmount: '25,200,000 LFG',
      raiseAmount: '$1,890,000',
      cakeToBurn: '$0',
      distributionRatio: 0.9,
    },
    currency: coreTokens.lfg,
    token: coreTokens.lfg_receit_base,
    reward: coreTokens.lfg_receit_base,
    releaseBlockNumber: 20685666,
    campaignId: '511400000',
    articleUrl: 'https://medium.com/@lfgswap/lfgswap-enters-the-wonderland-called-arbitrum-69390d31c51f',
    tokenOfferingPrice: 1,
    version: 3.2,
  },
  {
    id: 'ARB—LFG—BASE',
    address: '0xF0F411F1371503EA445Fb4A20C70917652627EC5',
    isActive: false,
    name: 'ARB—LFG—BASE',
    poolBasic: {
      saleAmount: '1,200,000 LFG(BASE)',
      raiseAmount: '1,200,000 LFG',
      cakeToBurn: '$0',
      distributionRatio: 0.1,
    },
    poolUnlimited: {
      saleAmount: '25,200,000 LFG',
      raiseAmount: '$1,890,000',
      cakeToBurn: '$0',
      distributionRatio: 0.9,
    },
    currency: arbTokens.lfg,
    token: arbTokens.lfg_receit,
    reward: arbTokens.lfg_receit,
    releaseBlockNumber: 20685666,
    campaignId: '511400000',
    articleUrl: 'https://medium.com/@lfgswap/lfgswap-enters-the-wonderland-called-arbitrum-69390d31c51f',
    tokenOfferingPrice: 1,
    version: 3.2,
  },

  // {
  //   id: 'IDO-OPBNB',
  //   address: '0xccC9BcE5c5d85d1E4761211538178AD91C1C2d09',
  //   isActive: true,
  //   name: 'ARB-LFG-opBNB',
  //   poolBasic: {
  //     saleAmount: '555,556 LFG(opBNB)',
  //     raiseAmount: '555,556 LFG',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.1,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '25,200,000 LFG',
  //     raiseAmount: '$1,890,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.9,
  //   },
  //   currency: arbTokens.lfg,
  //   token: opBNBTokens.lfg_receit_base,
  //   reward: opBNBTokens.lfg_receit_base,
  //   releaseBlockNumber: 20685666,
  //   campaignId: '511400000',
  //   articleUrl: 'https://medium.com/@lfgswap/6729786f3642',
  //   tokenOfferingPrice: 1,
  //   version: 3.2,
  // },
  // {
  //   id: 'IDO-OPBNB',
  //   address: '0x4B4c36e726Af82A31E74C95B46dEda0B1d43ffB8',
  //   isActive: true,
  //   name: 'BASE-LFG-opBNB',
  //   poolBasic: {
  //     saleAmount: '555,556 LFG(opBNB)',
  //     raiseAmount: '555,556 LFG',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.1,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '25,200,000 LFG',
  //     raiseAmount: '$1,890,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.9,
  //   },
  //   currency: baseTokens.lfg,
  //   token: opBNBTokens.lfg_receit_base,
  //   reward: opBNBTokens.lfg_receit_base,
  //   releaseBlockNumber: 20685666,
  //   campaignId: '511400000',
  //   articleUrl: 'https://medium.com/@lfgswap/6729786f3642',
  //   tokenOfferingPrice: 1,
  //   version: 3.2,
  // },
  // ,
  // {
  //   id: 'IDO-OPBNB',
  //   address: '0x6BE73868e9D95E33641C0A0e1499010cf21d09a3',
  //   isActive: true,
  //   name: 'CORE-LFG-opBNB',
  //   poolBasic: {
  //     saleAmount: '3,055,556 LFG(opBNB)',
  //     raiseAmount: '3,055,556  LFG',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.1,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '25,200,000 LFG',
  //     raiseAmount: '$1,890,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.9,
  //   },
  //   currency: coreTokens.lfg,
  //   token: opBNBTokens.lfg_receit_base,
  //   reward: opBNBTokens.lfg_receit_base,
  //   releaseBlockNumber: 20685666,
  //   campaignId: '511400000',
  //   articleUrl: 'https://medium.com/@lfgswap/6729786f3642',
  //   tokenOfferingPrice: 1,
  //   version: 3.2,
  // },
  // {
  //   id: 'IDO-OPBNB',
  //   address: '0x3CF02193DC347a6BA1b8705af33E66d08c3f9627',
  //   isActive: true,
  //   name: 'ETHW-LFG-opBNB',
  //   poolBasic: {
  //     saleAmount: '833,333 LFG(opBNB)',
  //     raiseAmount: '833,333 LFG',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.1,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '25,200,000 LFG',
  //     raiseAmount: '$1,890,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.9,
  //   },
  //   currency: bscTokens.lfg,
  //   token: opBNBTokens.lfg_receit_base,
  //   reward: opBNBTokens.lfg_receit_base,
  //   releaseBlockNumber: 20685666,
  //   campaignId: '511400000',
  //   articleUrl: 'https://medium.com/@lfgswap/6729786f3642',
  //   tokenOfferingPrice: 1,
  //   version: 3.2,
  // },
  // {
  //   id: 'IDO-OPBNB',
  //   address: '0x8708C4a6BA9Ed531409feCA2F8D397bEedFC9e0B',
  //   isActive: false,
  //   name: 'IDO_LFG_BNB',
  //   poolBasic: {
  //     saleAmount: '5,000,000 LFG',
  //     raiseAmount: '500 BNB',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.1,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '25,200,000 LFG',
  //     raiseAmount: '$1,890,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.9,
  //   },
  //   currency: opBNBTokens.bnb,
  //   token: opBNBTokens.lfg,
  //   reward: opBNBTokens.lfg,
  //   releaseBlockNumber: 20685666,
  //   campaignId: '511400000',
  //   articleUrl: 'https://medium.com/@lfgswap/6729786f3642',
  //   tokenOfferingPrice: 0.0001,
  //   version: 3.2,
  // },
]

export default idos
