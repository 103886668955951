import { ChainId, WETH,Token } from '@pancakeswap/sdk'
import { LFG_OPBNB } from './common'

export const opBNBTokens = {
  weth: WETH[ChainId.OPBNB],
  // bnb here points to the wbnb contract. Wherever the currency WETH is required, conditional checks for the symbol 'WETH' can be used
  // lfg: LFG_ZKS,
  // usdc: USDC[ChainId.ZKS],
  bnb: new Token(
    ChainId.OPBNB,
    '0x4200000000000000000000000000000000000006',
    18,
    'BNB',
    'Wrapped BNB',
    'https://weth.io'
  ),
  lfg: LFG_OPBNB,
  lfg_receit_base: new Token(
    ChainId.OPBNB,
    '0xE5552e0318531F9Ec585c83bDc8956C08Bf74b71',
    18,
    'LFG(opBNB)',
    'LFG(opBNB)',
    'https://lfgswap.finance',
  ),

  // usdt: USDT[ChainId.ZKS],
  //   Cheems: new Token(
  //     ChainId.ZKS,
  //     '0xd599dA85F8Fc4877e61f547dFAcffe1238A7149E',
  //     18,
  //     'Cheems',
  //     'Cheems',
  //     'https://www.lfgswap.finance/',
  //   ),
}
