import _define_property from "@swc/helpers/src/_define_property.mjs";
import JSBI from "jsbi";
import { Token } from "./entities/token";
export var ChainId;
(function(ChainId) {
    ChainId[ChainId["ETHEREUM"] = 1] = "ETHEREUM";
    ChainId[ChainId["RINKEBY"] = 4] = "RINKEBY";
    ChainId[ChainId["GOERLI"] = 5] = "GOERLI";
    ChainId[ChainId["BSC"] = 10001] = "BSC";
    ChainId[ChainId["CORE"] = 1116] = "CORE";
    ChainId[ChainId["ARBITRUM"] = 42161] = "ARBITRUM";
    ChainId[ChainId["BSC_TESTNET"] = 77] = "BSC_TESTNET";
    ChainId[ChainId["ZKS"] = 324] = "ZKS";
    ChainId[ChainId["BASE"] = 8453] = "BASE";
    ChainId[ChainId["OPBNB"] = 204] = "OPBNB";
})(ChainId || (ChainId = {}));
export var TradeType;
(function(TradeType) {
    TradeType[TradeType["EXACT_INPUT"] = 0] = "EXACT_INPUT";
    TradeType[TradeType["EXACT_OUTPUT"] = 1] = "EXACT_OUTPUT";
})(TradeType || (TradeType = {}));
export var Rounding;
(function(Rounding) {
    Rounding[Rounding["ROUND_DOWN"] = 0] = "ROUND_DOWN";
    Rounding[Rounding["ROUND_HALF_UP"] = 1] = "ROUND_HALF_UP";
    Rounding[Rounding["ROUND_UP"] = 2] = "ROUND_UP";
})(Rounding || (Rounding = {}));
export var FACTORY_ADDRESS = "0xf66cef53c518659bFA0A9a4Aa07445AF08bf9B3a";
// // TODO: ETH This is test version, do not depends on it
var FACTORY_ADDRESS_ETH = "0xD93801d7D3a368D94A3A32E97A20f7aC1948a5dB";
var _obj;
export var FACTORY_ADDRESS_MAP = (_obj = {}, _define_property(_obj, ChainId.ETHEREUM, FACTORY_ADDRESS_ETH), _define_property(_obj, ChainId.RINKEBY, FACTORY_ADDRESS_ETH), _define_property(_obj, ChainId.GOERLI, FACTORY_ADDRESS_ETH), _define_property(_obj, ChainId.BSC, FACTORY_ADDRESS), _define_property(_obj, ChainId.BSC_TESTNET, "0x6725f303b657a9451d8ba641348b6761a6cc7a17"), _define_property(_obj, ChainId.CORE, "0xA1ADD165AED06D26fC1110b153ae17a5A5ae389e"), _define_property(_obj, ChainId.ARBITRUM, "0xE5552e0318531F9Ec585c83bDc8956C08Bf74b71"), _define_property(_obj, ChainId.ZKS, "0x0793dD49779A1e74d239E53537180F1b6a9Ef6dA"), _define_property(_obj, ChainId.BASE, "0xE5552e0318531F9Ec585c83bDc8956C08Bf74b71"), _define_property(_obj, ChainId.OPBNB, "0xc92F5355b21CA3131014d018C3c126F328C7d390"), _obj);
var INIT_CODE_HASH_ETH = "0xf77c0048ce0c3698e2ef81b6c2f56580d3d5e69c18f69ed6223f22d2f411832b";
var _obj1;
export var INIT_CODE_HASH_MAP = (_obj1 = {}, _define_property(_obj1, ChainId.ETHEREUM, INIT_CODE_HASH_ETH), _define_property(_obj1, ChainId.RINKEBY, INIT_CODE_HASH_ETH), _define_property(_obj1, ChainId.GOERLI, INIT_CODE_HASH_ETH), _define_property(_obj1, ChainId.BSC, INIT_CODE_HASH_ETH), _define_property(_obj1, ChainId.BSC_TESTNET, "0xd0d4c4cd0848c93cb4fd1f498d7013ee6bfb25783ea21593d5834f5d250ece66"), _define_property(_obj1, ChainId.CORE, "0xca97d699049b87540a23ade75d58fb29000ad13083c8eef7e5e0c6b5fb321180"), _define_property(_obj1, ChainId.ARBITRUM, "0xca97d699049b87540a23ade75d58fb29000ad13083c8eef7e5e0c6b5fb321180"), _define_property(_obj1, ChainId.BASE, "0xca97d699049b87540a23ade75d58fb29000ad13083c8eef7e5e0c6b5fb321180"), _define_property(_obj1, ChainId.OPBNB, "0xca97d699049b87540a23ade75d58fb29000ad13083c8eef7e5e0c6b5fb321180"), _obj1);
export var MINIMUM_LIQUIDITY = JSBI.BigInt(1000);
// exports for internal consumption
export var ZERO = JSBI.BigInt(0);
export var ONE = JSBI.BigInt(1);
export var TWO = JSBI.BigInt(2);
export var THREE = JSBI.BigInt(3);
export var FIVE = JSBI.BigInt(5);
export var TEN = JSBI.BigInt(10);
export var _100 = JSBI.BigInt(100);
export var _9975 = JSBI.BigInt(9975);
export var _10000 = JSBI.BigInt(10000);
export var MaxUint256 = JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff");
export var SolidityType;
(function(SolidityType) {
    SolidityType["uint8"] = "uint8";
    SolidityType["uint256"] = "uint256";
})(SolidityType || (SolidityType = {}));
var _obj2;
export var SOLIDITY_TYPE_MAXIMA = (_obj2 = {}, _define_property(_obj2, SolidityType.uint8, JSBI.BigInt("0xff")), _define_property(_obj2, SolidityType.uint256, JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")), _obj2);
var _obj3;
export var WETH9 = (_obj3 = {}, _define_property(_obj3, ChainId.ETHEREUM, new Token(ChainId.ETHEREUM, "0x7Bf88d2c0e32dE92CdaF2D43CcDc23e8Edfd5990", 18, "WETHW", "Wrapped Ether", "https://weth.io")), _define_property(_obj3, ChainId.RINKEBY, new Token(ChainId.RINKEBY, "0xc778417E063141139Fce010982780140Aa0cD5Ab", 18, "WETH", "Wrapped Ether", "https://weth.io")), _define_property(_obj3, ChainId.GOERLI, new Token(ChainId.GOERLI, "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6", 18, "WETH", "Wrapped Ether", "https://weth.io")), _obj3);
var _obj4;
export var WETH = (_obj4 = {}, _define_property(_obj4, ChainId.ETHEREUM, new Token(ChainId.ETHEREUM, "0x418D75f65a02b3D53B2418FB8E1fe493759c7605", 18, "WBNB", "Wrapped WETH", "https://weth.io")), _define_property(_obj4, ChainId.BSC, new Token(ChainId.BSC, "0x7Bf88d2c0e32dE92CdaF2D43CcDc23e8Edfd5990", 18, "WETHW", "Wrapped ETHW", "https://weth.io")), _define_property(_obj4, ChainId.CORE, new Token(ChainId.CORE, "0x40375C92d9FAf44d2f9db9Bd9ba41a3317a2404f", 18, "CORE", "Wrapped CORE", "https://weth.io")), _define_property(_obj4, ChainId.ARBITRUM, new Token(ChainId.ARBITRUM, "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1", 18, "WETH", "Wrapped ETH", "https://weth.io")), _define_property(_obj4, ChainId.BSC_TESTNET, new Token(ChainId.BSC_TESTNET, "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd", 18, "WBNB", "Wrapped WETH", "https://weth.io")), _define_property(_obj4, ChainId.ZKS, new Token(ChainId.ZKS, "0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91", 18, "WETH", "Wrapped WETH", "https://weth.io")), _define_property(_obj4, ChainId.BASE, new Token(ChainId.BASE, "0x4200000000000000000000000000000000000006", 18, "WETH", "Wrapped ETH", "https://weth.io")), _define_property(_obj4, ChainId.OPBNB, new Token(ChainId.OPBNB, "0x4200000000000000000000000000000000000006", 18, "WBNB", "Wrapped WETH", "https://weth.io")), _obj4);
var _obj5;
export var WNATIVE = (_obj5 = {}, _define_property(_obj5, ChainId.ETHEREUM, WETH9[ChainId.ETHEREUM]), _define_property(_obj5, ChainId.RINKEBY, WETH9[ChainId.RINKEBY]), _define_property(_obj5, ChainId.GOERLI, WETH9[ChainId.GOERLI]), _define_property(_obj5, ChainId.BSC, WETH[ChainId.BSC]), _define_property(_obj5, ChainId.CORE, WETH[ChainId.CORE]), _define_property(_obj5, ChainId.ARBITRUM, WETH[ChainId.ARBITRUM]), _define_property(_obj5, ChainId.BSC_TESTNET, WETH[ChainId.BSC_TESTNET]), _define_property(_obj5, ChainId.ZKS, WETH[ChainId.ZKS]), _define_property(_obj5, ChainId.BASE, WETH[ChainId.BASE]), _define_property(_obj5, ChainId.OPBNB, WETH[ChainId.OPBNB]), _obj5);
var _obj6;
export var NATIVE = (_obj6 = {}, _define_property(_obj6, ChainId.ETHEREUM, {
    name: "Ether",
    symbol: "ETH",
    decimals: 18
}), _define_property(_obj6, ChainId.RINKEBY, {
    name: "Rinkeby Ether",
    symbol: "RIN",
    decimals: 18
}), _define_property(_obj6, ChainId.GOERLI, {
    name: "Goerli Ether",
    symbol: "GOR",
    decimals: 18
}), _define_property(_obj6, ChainId.BSC, {
    name: "Ether",
    symbol: "ETHW",
    decimals: 18
}), _define_property(_obj6, ChainId.CORE, {
    name: "Core",
    symbol: "CORE",
    decimals: 18
}), _define_property(_obj6, ChainId.ARBITRUM, {
    name: "Ethereum",
    symbol: "ETH",
    decimals: 18
}), _define_property(_obj6, ChainId.ZKS, {
    name: "Ethereum",
    symbol: "ETH",
    decimals: 18
}), _define_property(_obj6, ChainId.BASE, {
    name: "Ethereum",
    symbol: "ETH",
    decimals: 18
}), _define_property(_obj6, ChainId.BSC_TESTNET, {
    name: "Binance Chain Native Token",
    symbol: "tBNB",
    decimals: 18
}), _define_property(_obj6, ChainId.OPBNB, {
    name: "Binance Chain Native Token",
    symbol: "BNB",
    decimals: 18
}), _obj6);
// Slippage config
export var SLIPPAGE_CONFIG = [
    {
        token: "0xF7a0b80681eC935d6dd9f3Af9826E68B99897d6D",
        value: 100,
        chainId: 1116
    },
    {
        token: "0x1Cd20E4776fdB865B1260fafA183B0f2E0BCC83e",
        value: 1000,
        chainId: 324
    }, 
];
