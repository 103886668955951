

import { ChainId, Token, WETH } from '@pancakeswap/sdk'
import { LFG_ARB, USDC, USDT } from './common'

export const zksTokens = {
  weth: WETH[ChainId.ZKS],
  // bnb here points to the wbnb contract. Wherever the currency WETH is required, conditional checks for the symbol 'WETH' can be used
  // lfg: LFG_ZKS,
  usdc: USDC[ChainId.ZKS],
  // usdt: USDT[ChainId.ZKS],
  Cheems: new Token(
    ChainId.ZKS,
    '0xd599dA85F8Fc4877e61f547dFAcffe1238A7149E',
    18,
    'Cheems',
    'Cheems',
    'https://www.lfgswap.finance/',
  ),
}
