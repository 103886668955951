import { getPancakeBunniesAddress } from 'utils/addressHelpers'

export const nftsBaseUrl = '/nfts'
export const pancakeBunniesAddress = getPancakeBunniesAddress()

export const WolfFarmConfig = {
  42161: {
    WolfFarm: '0xF67C0F87BB87bE33FB258B04829D1bD3B6475e4C',
    totalWOW: 250000,
    totalLFG: 3700,
    BabyFarm: '0xd16C1E6D348dc2D57bC06Be767FD2e54C5971C07',
  },
  1116: {
    WolfFarm: '0x0A2DC38977502BB222265e79569Bb77B211a38f5',
    totalWOW: 300000,
    totalLFG: 6600,
    BabyFarm: '0x4a8c1845D9FD0c118c645E54d408c2e290905AF7',
  },
}
