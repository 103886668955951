import { Token, ChainId } from '@pancakeswap/sdk'
import { bscTokens } from '@pancakeswap/tokens'
import { CAKE_BNB_LP_MAINNET } from './lp'
import { Ifo } from './types'

export const cakeBnbLpToken = new Token(ChainId.BSC, CAKE_BNB_LP_MAINNET, 18, 'LFG-WETH LP')

const ifos: Ifo[] = [
  // {
  //   id: 'lfg',
  //   address: '0x98828e9D69E49104a62ecb4FA3d6F9e06A295CBA',
  //   isActive: true,
  //   name: 'lfg',
  //   poolBasic: {
  //     saleAmount: '2,800,000 WOM',
  //     raiseAmount: '$210,000',
  //     cakeToBurn: '$0',
  //     distributionRatio: 0.1,
  //   },
  //   poolUnlimited: {
  //     saleAmount: '25,200,000 WOM',
  //     raiseAmount: '$1,890,000',
  //     cakeToBurn: '$30000',
  //     distributionRatio: 0.9,
  //   },
  //   currency: bscTokens.lfg,
  //   token: bscTokens.wom,
  //   releaseBlockNumber: 20685666,
  //   campaignId: '511400000',
  //   articleUrl: 'https://lfgswap.finance/voting/proposal/bafkreieqv7mbzmumyftstt6l32x6okfzq4syrea7k5zbqgohhcekcvbduu',
  //   tokenOfferingPrice: 0.075,
  //   version: 3.2,
  //   twitterUrl: 'https://twitter.com/WombatExchange',
  //   description: 'Wombat Exchange is a next generation multi-chain stableswap native to WETH Chain.',
  //   vestingTitle: 'Earn $WOM by staking stablecoins; Lock $WOM to maximize stablecoin yields',
  // },
]

export default ifos
