import { BigNumber } from '@ethersproject/bignumber'
import Trans from 'components/Trans'
import { VaultKey } from 'state/types'
import { arbTokens, bscTokens, coreTokens, serializeToken } from '@pancakeswap/tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

export const MAX_LOCK_DURATION = 31536000
export const UNLOCK_FREE_DURATION = 604800
export const ONE_WEEK_DEFAULT = 604800
export const BOOST_WEIGHT = BigNumber.from('20000000000000')
export const DURATION_FACTOR = BigNumber.from('31536000')

export const vaultPoolConfig = {
  [VaultKey.CakeVaultV1]: {
    name: <Trans>Auto LFG</Trans>,
    description: <Trans>Automatic restaking</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 4500000,
    tokenImage: {
      primarySrc: `/images/tokens/${bscTokens.lfg.address}.svg`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
  [VaultKey.CakeVault]: {
    name: <Trans>Stake LFG</Trans>,
    description: <Trans>Stake, Earn – And more!</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 4500000,
    tokenImage: {
      primarySrc: `/images/tokens/${bscTokens.lfg.address}.svg`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
  [VaultKey.CakeFlexibleSideVault]: {
    name: <Trans>Flexible LFG</Trans>,
    description: <Trans>Flexible staking on the side.</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 4500000,
    tokenImage: {
      primarySrc: `/images/tokens/${bscTokens.lfg.address}.svg`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
  [VaultKey.IfoPool]: {
    name: 'IFO LFG',
    description: <Trans>Stake LFG to participate in IFOs</Trans>,
    autoCompoundFrequency: 1,
    gasLimit: 4500000,
    tokenImage: {
      primarySrc: `/images/tokens/${bscTokens.lfg.address}.svg`,
      secondarySrc: `/images/tokens/ifo-pool-icon.svg`,
    },
  },
} as const

export const getLivePools = (chainId: number): SerializedPoolConfig[] => {
  const pools = livePools.filter((pool) => pool.stakingToken.chainId === chainId)
  return pools
}

const livePools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: bscTokens.lfg,
    earningToken: bscTokens.lfg,
    contractAddress: {
      56: '0x23e9354Ea6E8E71DB692AFbbf09b2C9A3bcDd8aF',
      10001: '0x23e9354Ea6E8E71DB692AFbbf09b2C9A3bcDd8aF',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '10',
    // isFinished: false,
    depositFee: 0,
    withdrawFee: 0,
    harvestFee: 3,
    version: 3,
  },
  {
    sousId: 6,
    stakingToken: bscTokens.lfg,
    earningToken: bscTokens.lfg,
    contractAddress: {
      56: '0x29CA6A9EE6a9B740299E318F3d6C1AA6414157b8',
      10001: '0x29CA6A9EE6a9B740299E318F3d6C1AA6414157b8',
    },
    poolCategory: PoolCategory.AUTO,
    tokenPerBlock: '4.85',
    depositFee: 0,
    withdrawFee: 0,
    harvestFee: 3,
    version: 3,
  },
  {
    sousId: 7,
    stakingToken: bscTokens.weth,
    earningToken: bscTokens.lfg,
    contractAddress: {
      56: '0x6b143830C6560F7157c372D97D96D71281eEc0AA',
      10001: '0x6b143830C6560F7157c372D97D96D71281eEc0AA',
    },
    poolCategory: PoolCategory.BINANCE,
    tokenPerBlock: '2.05',
    depositFee: 0,
    withdrawFee: 0,
    harvestFee: 3,
    version: 3,
  },
  {
    sousId: 8,
    stakingToken: bscTokens.lfg,
    earningToken: bscTokens.eid,
    contractAddress: {
      56: '0xcAB76a17FCC9BD6128979De1c8Fd49517B9107e8',
      10001: '0xcAB76a17FCC9BD6128979De1c8Fd49517B9107e8',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '10',
    depositFee: 0,
    withdrawFee: 0,
    harvestFee: 3,
    version: 3,
  },
  {
    sousId: 9,
    stakingToken: coreTokens.lfg,
    earningToken: coreTokens.lfg,
    contractAddress: {
      56: '0xfa0B0DD7827909BBDf7A1d7897DC17f1688d6fdD',
      1116: '0xcF17abb2CeA7e96eD1E35E0F3FAC919cFECad2F3',
    },
    poolCategory: PoolCategory.AUTO,
    tokenPerBlock: '0.65',
    depositFee: 0,
    withdrawFee: 0,
    harvestFee: 3,
    version: 3,
  },
  {
    sousId: 10,
    stakingToken: coreTokens.lfg,
    earningToken: coreTokens.cid,
    contractAddress: {
      56: '0xfa0B0DD7827909BBDf7A1d7897DC17f1688d6fdD',
      1116: '0x4EAb3c3c650C815146D178581B326d25D6022869',
    },
    poolCategory: PoolCategory.AUTO,
    tokenPerBlock: '0.5787',
    depositFee: 0,
    withdrawFee: 0,
    harvestFee: 3,
    version: 3,
  },
  {
    sousId: 11,
    stakingToken: coreTokens.lfg,
    earningToken: coreTokens.happy,
    contractAddress: {
      56: '0xfa0B0DD7827909BBDf7A1d7897DC17f1688d6fdD',
      1116: '0xB598Edb46DD06223022de9f6570F97A24057cAd2',
    },
    poolCategory: PoolCategory.AUTO,
    tokenPerBlock: '1.1574',
    depositFee: 0,
    withdrawFee: 0,
    harvestFee: 3,
    version: 3,
  },
  {
    sousId: 12,
    stakingToken: coreTokens.lfg,
    earningToken: coreTokens.weth,
    contractAddress: {
      56: '0xfa0B0DD7827909BBDf7A1d7897DC17f1688d6fdD',
      1116: '0x0EAeED6a6600F355Ae0b270b51f7dCc73C221Ded',
    },
    poolCategory: PoolCategory.AUTO,
    tokenPerBlock: '0.0099',
    depositFee: 0,
    withdrawFee: 0,
    harvestFee: 3,
    version: 3,
  },
  {
    sousId: 13,
    stakingToken: coreTokens.lfg,
    earningToken: coreTokens.cdo,
    contractAddress: {
      56: '0xfa0B0DD7827909BBDf7A1d7897DC17f1688d6fdD',
      1116: '0xF86243fD0Bf93D592c5B25F4b0785347cbA5494D',
    },
    poolCategory: PoolCategory.AUTO,
    tokenPerBlock: '0.81',
    depositFee: 0,
    withdrawFee: 0,
    harvestFee: 3,
    version: 3,
  },
  {
    sousId: 14,
    stakingToken: arbTokens.weth,
    earningToken: arbTokens.lfg,
    contractAddress: {
      56: '0xfa0B0DD7827909BBDf7A1d7897DC17f1688d6fdD',
      42161: '0xDBeF73A4FeAb5E84b18aB3Ac3bf4E97dE393D909',
    },
    poolCategory: PoolCategory.BINANCE,
    tokenPerBlock: '0.1735',
    depositFee: 0,
    withdrawFee: 0,
    harvestFee: 3,
    version: 3,
  },
  {
    sousId: 15,
    stakingToken: arbTokens.lfg,
    earningToken: arbTokens.lfg,
    contractAddress: {
      56: '0xfa0B0DD7827909BBDf7A1d7897DC17f1688d6fdD',
      42161: '0x206Bd814C0424e3c70f32879cBD3F91d741f26d5',
    },
    poolCategory: PoolCategory.AUTO,
    tokenPerBlock: '0.347',
    depositFee: 0,
    withdrawFee: 0,
    harvestFee: 3,
    version: 3,
  },
  {
    sousId: 16,
    stakingToken: arbTokens.arb,
    earningToken: arbTokens.lfg,
    contractAddress: {
      56: '0xfa0B0DD7827909BBDf7A1d7897DC17f1688d6fdD',
      42161: '0x516106922B16ccC11390B4F6033F470938AD5d3A',
    },
    poolCategory: PoolCategory.AUTO,
    tokenPerBlock: '0.1735',
    depositFee: 0,
    withdrawFee: 0,
    harvestFee: 3,
    version: 3,
  },
  {
    sousId: 17,
    stakingToken: arbTokens.usdt,
    earningToken: arbTokens.lfg,
    contractAddress: {
      56: '0xfa0B0DD7827909BBDf7A1d7897DC17f1688d6fdD',
      42161: '0x76f1165Fe04F0A8Ac6dAe7f0012E3B4686F05a01',
    },
    poolCategory: PoolCategory.AUTO,
    tokenPerBlock: '0.1735',
    depositFee: 0,
    withdrawFee: 0,
    harvestFee: 3,
    version: 3,
  },
 
 
  {
    sousId: 0,
    stakingToken: coreTokens.lfg,
    earningToken: coreTokens.lfg,
    contractAddress: {
      56: '0xfa0B0DD7827909BBDf7A1d7897DC17f1688d6fdD',
      1116: '0x00801Df22566E6F1b7Eb2DCaa2c794ca6daD3D0A',
    },
    poolCategory: PoolCategory.AUTO,
    tokenPerBlock: '10',
    depositFee: 0,
    withdrawFee: 0,
    harvestFee: 3,
    version: 3,
  },
  {
    sousId: 0,
    stakingToken: arbTokens.lfg,
    earningToken: arbTokens.lfg,
    contractAddress: {
      56: '0xfa0B0DD7827909BBDf7A1d7897DC17f1688d6fdD',
      42161: '0x392a4ee806B6d32a894Bc38bb904953D4bF14768',
    },
    poolCategory: PoolCategory.AUTO,
    tokenPerBlock: '10',
    depositFee: 0,
    withdrawFee: 0,
    harvestFee: 3,
    version: 3,
  },

  {
    sousId: 1,
    stakingToken: bscTokens.yzz,
    earningToken: bscTokens.weth,
    contractAddress: {
      56: '0x985a20998c7c59e98005c556393215e39c9a4978',
      10001: '0x0496fb74A7aE2ee7dA1f9025B486fcf3cAaEA5EE',
    },
    poolCategory: PoolCategory.AUTO,
    tokenPerBlock: '0.001',
    depositFee: 0,
    withdrawFee: 0,
    harvestFee: 3,
    version: 3,
  },
  {
    sousId: 2,
    stakingToken: bscTokens.lfg,
    earningToken: bscTokens.bhc,
    contractAddress: {
      56: '0x985a20998c7c59e98005c556393215e39c9a4978',
      10001: '0xaE60ba6Ff5cCb6c4133D4530FCb5934a827c8B18',
    },
    poolCategory: PoolCategory.AUTO,
    tokenPerBlock: '0.00125',
    depositFee: 0,
    withdrawFee: 0,
    harvestFee: 3,
    version: 3,
  },
  {
    sousId: 3,
    stakingToken: bscTokens.lfg,
    earningToken: bscTokens.shibaW,
    contractAddress: {
      56: '0x985a20998c7c59e98005c556393215e39c9a4978',
      10001: '0x13D1D3626E6254093e72Ec045CD80487afb11201',
    },
    poolCategory: PoolCategory.AUTO,
    tokenPerBlock: '100308951.57',
    depositFee: 0,
    withdrawFee: 0,
    harvestFee: 3,
    version: 3,
  },
  {
    sousId: 4,
    stakingToken: bscTokens.lfg,
    earningToken: bscTokens.yzz,
    contractAddress: {
      56: '0x09CCB483333be62CA648fE51139b3Be88a9a60f4',
      10001: '0x09CCB483333be62CA648fE51139b3Be88a9a60f4',
    },
    poolCategory: PoolCategory.AUTO,
    tokenPerBlock: '3.76',
    depositFee: 0,
    withdrawFee: 0,
    harvestFee: 3,
    version: 3,
  },
  {
    sousId: 5,
    stakingToken: bscTokens.lfg,
    earningToken: bscTokens.shibW,
    contractAddress: {
      56: '0x8624018fd72C64320abA4A9C15BDcf923C718889',
      10001: '0x8624018fd72C64320abA4A9C15BDcf923C718889',
    },
    poolCategory: PoolCategory.AUTO,
    tokenPerBlock: '10030895',
    depositFee: 0,
    withdrawFee: 0,
    harvestFee: 3,
    version: 3,
  },
  {
    sousId: 19,
    stakingToken: arbTokens.lfg,
    earningToken: arbTokens.mts,
    contractAddress: {
      56: '0xfa0B0DD7827909BBDf7A1d7897DC17f1688d6fdD',
      42161: '0xC8c32d9f90edbbc1397E22AA736A619Ed1e9e700',
    },
    poolCategory: PoolCategory.AUTO,
    tokenPerBlock: '0.496',
    depositFee: 0,
    withdrawFee: 0,
    harvestFee: 3,
    version: 3,
    isFinished: true,
  },
  {
    sousId: 18,
    stakingToken: arbTokens.lfg,
    earningToken: arbTokens.wow,
    contractAddress: {
      56: '0xfa0B0DD7827909BBDf7A1d7897DC17f1688d6fdD',
      42161: '0x283601eFC904b17715e25BCd8901Ac2B69575723',
    },
    poolCategory: PoolCategory.AUTO,
    tokenPerBlock: '34.7',
    depositFee: 0,
    withdrawFee: 0,
    harvestFee: 3,
    version: 3,
    isFinished: true,
  },
  // {
  //   sousId: 293,
  //   stakingToken: bscTokens.lfg,
  //   earningToken: bscTokens.usdt,
  //   contractAddress: {
  //     56: '0xC9cf6f73343cCE3B7Bc69617BBb17f24236D8728',
  //     77: '0xC9cf6f73343cCE3B7Bc69617BBb17f24236D8728',
  //     97: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '0.2604',
  //   version: 3,
  // },
  // {
  //   sousId: 292,
  //   stakingToken: bscTokens.lfg,
  //   earningToken: bscTokens.sfund,
  //   contractAddress: {
  //     56: '0x449EC6B2779A3F63E6c2527CBB162A38Ef199d7D',
  //     97: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '0.1071',
  //   version: 3,
  // },
  // {
  //   sousId: 291,
  //   stakingToken: bscTokens.lfg,
  //   earningToken: bscTokens.pstake,
  //   contractAddress: {
  //     56: '0x56D6955Ba6404647191DD7A5D65A5c9Fe43905e1',
  //     97: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '1.1574',
  //   version: 3,
  // },
  // {
  //   sousId: 290,
  //   stakingToken: bscTokens.lfg,
  //   earningToken: bscTokens.peel,
  //   contractAddress: {
  //     56: '0x288d1aD79c113552B618765B4986f7DE679367Da',
  //     97: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '4.34',
  //   version: 3,
  // },
  // {
  //   sousId: 289,
  //   stakingToken: bscTokens.lfg,
  //   earningToken: bscTokens.shell,
  //   contractAddress: {
  //     56: '0x595B7AF4F1828AB4953792482b01B2AFC4A46b72',
  //     97: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '89.699',
  //   version: 3,
  // },
  // {
  //   sousId: 288,
  //   stakingToken: bscTokens.lfg,
  //   earningToken: bscTokens.high,
  //   contractAddress: {
  //     56: '0x28cc814bE3B994187B7f8Bfed10516A84A671119',
  //     97: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '0.1332',
  //   version: 3,
  // },
  // {
  //   sousId: 287,
  //   stakingToken: bscTokens.lfg,
  //   earningToken: bscTokens.ole,
  //   contractAddress: {
  //     56: '0xda6F750be1331963E5772BEe757062f6bddcEA4C',
  //     97: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '2.2569',
  //   version: 3,
  // },
  // {
  //   sousId: 286,
  //   stakingToken: bscTokens.lfg,
  //   earningToken: bscTokens.trivia,
  //   contractAddress: {
  //     56: '0x86471019Bf3f403083390AC47643062e15B0256e',
  //     97: '',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '4.976',
  //   version: 3,
  // },
].map((p) => ({
  ...p,
  stakingToken: serializeToken(p.stakingToken),
  earningToken: serializeToken(p.earningToken),
}))

// known finished pools
const finishedPools = [
  
].map((p) => ({
  ...p,
  isFinished: true,
  stakingToken: serializeToken(p.stakingToken),
  earningToken: serializeToken(p.earningToken),
}))

const getPools = (chainId: number): SerializedPoolConfig[] => {
  const pools = livePools.filter((pool) => pool.stakingToken.chainId === chainId)
  pools.concat(finishedPools.filter((pool) => pool.stakingToken.chainId === chainId))
  return pools
}
export default getPools

export const allPools = [...livePools, ...finishedPools]
